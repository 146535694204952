import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import {firebaseConnect, firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import Gallery from 'react-grid-gallery';
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    photosDeleteButton: {
        margin: "20px",
        display: "block",
        marginLeft: "auto",
    }
});

class GalleryPage extends Component {
    state = {
        progress: 0,
        imagesURLs: [],
        galleryImages: [],
        selectedImages: [],
        categoryFilteredGallery: []
    };

    static getDerivedStateFromProps(props, current_state) {
        console.log(props.images);
        if (props.images)
            if (current_state.imagesURLs !== props.images) {
                const galleryImages = [];
                props.images.map(obj => {
                    galleryImages.push(
                        {
                            src: obj.value.path,
                            thumbnail: obj.value.thumbnail,
                            thumbnailWidth: 200,
                            thumbnailHeight: 200,
                            category: obj.value.category,
                            key: obj.key,
                        }
                    )
                });

                const categoryFilteredGallery = galleryImages.filter(elem => elem.category === props.category);

                return {
                    imagesURLs: props.images.imagesURLs,
                    galleryImages: galleryImages,
                    categoryFilteredGallery: categoryFilteredGallery
                }
            }
        return null
    }

    handleUploadStart = () => this.setState({progress: 0});
    handleProgress = progress => this.setState({progress});
    handleUploadError = error => {
        this.props.enqueueSnackbar("Błąd podczas przesyłania obrazka --- ." + error, {
            variant: 'error',
        });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        this.props.enqueueSnackbar("Obrazek dodany - pojawi się w ciągu kilku sekund.", {
            variant: 'success',
        });
    };

    handlePhotosDelete = () => {
        const imagesRef = this.props.firebase.ref('images');

        // console.log(this.props.images);

        const updates = {};
        this.state.selectedImages.map((index) => {
            const imageKey = this.state.categoryFilteredGallery[index].key;
            updates[imageKey] = null;
        });

        this.setState({
            selectedImages: []
        }, () => {
            imagesRef.update(updates)
                .then(() => {
                    console.log("Remove succeeded.");
                    this.props.enqueueSnackbar("Zdjęcia usunięte.", {
                        variant: 'success',
                    });
                })
                .catch((error) => {
                    console.log("Remove failed: " + error.message);
                    this.props.enqueueSnackbar("Ups, coś poszło nie tak... - " + error, {
                        variant: 'error',
                    });
                });
        });


    };

    onSelectImage = (index, image) => {
        let selectedImages = this.state.selectedImages;

        if (selectedImages.includes(index))
            selectedImages = selectedImages.filter(val => val !== index);
        else
            selectedImages.push(index);

        this.setState({
            selectedImages: selectedImages
        });

        // const images = this.state.galleryImages.slice();
        // const img = images[index];
        //
        // if (img.hasOwnProperty("isSelected"))
        //     img.isSelected = !img.isSelected;
        // else
        //     img.isSelected = true;
        //
        // this.setState({
        //     galleryImages: images
        // });

        //==================================

        // if(this.allImagesSelected(images)){
        //     this.setState({
        //         selectAllChecked: true
        //     });
        // }
        // else {
        //     this.setState({
        //         selectAllChecked: false
        //     });
        // }
    };

    render() {
        const {classes, firebase, category} = this.props;

        const categoryFilteredGallery = this.state.categoryFilteredGallery;
        this.state.selectedImages.map((index) => {
            categoryFilteredGallery[index].isSelected = true;
        });

        const deleteTooltip = `
            Usunięte zdjęcia znikną z galerii, 
            ale nie znikną z wybieraczek, w których są już używane. 
            Tej operacji nie można cofnąć!
        `;

        return (
            <div>
                <div className={classes.appBarSpacer}/>

                <CustomUploadButton
                    accept="image/*"
                    storageRef={firebase.storage().ref(`images/images_${category}`)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}
                    multiple
                    randomizeFilename
                >
                    Załaduj nowe zdjęcia
                </CustomUploadButton>
                <div className={classes.appBarSpacer}/>
                <LinearProgress variant="determinate" value={this.state.progress}/>

                <div className={classes.appBarSpacer}/>

                <Tooltip title={deleteTooltip} placement="top-end">
                    <Button variant="outlined" color="secondary" className={classes.photosDeleteButton}
                            onClick={this.handlePhotosDelete}>
                        Usuń zaznaczone zdjęcia
                    </Button>
                </Tooltip>

                <Gallery images={categoryFilteredGallery}
                         enableImageSelection={true}
                         onSelectImage={this.onSelectImage}
                />

            </div>
        );
    }
}

GalleryPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['images/_base', 'users']), // or { collection: 'todos' }
    firebaseConnect([
        'images'
    ]),
    connect((state) => ({
        images: state.firebase.ordered.images,
        // images: state.firestore.data.images && state.firestore.data.images["_base"],
        users: state.firestore.data.users,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(GalleryPage)