import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import CreateUserDialog from "../../Login/CreateUserDialog";
import UsersTable from "./UsersTable";
import {withSnackbar} from "notistack";
import * as firebase from "firebase";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import {UserIsNotAdmin} from "../../Login/AuthHOC";
import {withRouter} from "react-router-dom";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
});


class Users extends Component {

    loginAction = () => {
        this.props.firebase.login({
            email: 'test@test.com',
            password: 'testtest'
        });
    };
    createAction = (email, password, username, role, event) => {
        event.preventDefault();
        const config = {
            apiKey: "AIzaSyD6f5lu4k_Wrv0hk51o4InJFgeRkrHO2gc",
            authDomain: "wybierator-produktow.firebaseapp.com",
            databaseURL: "https://wybierator-produktow.firebaseio.com",
        };
        const secondaryApp = firebase.initializeApp(config, "Secondary");

        secondaryApp.auth().createUserWithEmailAndPassword(email, password)
            .then((firebaseUser) => {
                this.props.enqueueSnackbar("Utworzono użytkownika!", {
                    variant: 'success',
                });

                this.props.firestore.set({collection: 'users', doc: firebaseUser.user.uid},
                    {
                        username: username,
                        email: email,
                        role: role,
                        creationDate: new Date().toISOString().slice(0, 10)
                    },
                    {merge: true});

                secondaryApp.auth().signOut();
                secondaryApp.delete();

                this.props.firebase.auth().sendPasswordResetEmail(email)
                    .then(() => {
                        this.props.enqueueSnackbar("Wysłano email z prośbą o ustawienie hasła!", {
                            variant: 'success',
                        });
                    })
                    .catch((error) => {
                        this.props.enqueueSnackbar("Wystąpił błąd przy wysyłaniu emaila: " + error, {
                            variant: 'error',
                        });
                    })
            })
    };

    logoutAction = () => {
        this.props.firebase.logout()
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                <div className={classes.appBarSpacer}/>

                <CreateUserDialog onSubmit={this.createAction}/>

                {console.log(this.props.auth)}
                {console.log(this.props.profile)}
                {/*<Button onClick={this.loginAction}>Login</Button>*/}
                {/*<Button onClick={this.createAction}>Create</Button>*/}
                {/*<Button onClick={this.logoutAction}>LogOut</Button>*/}

                <UsersTable/>
            </div>
        );
    }
}

Users.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(Users);

export default compose(
    firestoreConnect(), // or { collection: 'todos' }
    connect((state) => ({
        // wybieraczki: state.firestore.ordered.wybieraczki
        profile: state.firebase.profile, // pass profile data as this.props.profile
        auth: state.firebase.auth // pass auth data as this.props.auth
    })),
    withSnackbar,
    withRouter,
    withStyles(styles),
    UserIsNotAdmin
)(Users)


