import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import {NavLink} from "react-router-dom";

export const mainListItems = (role) => (
    <div>
        <NavLink to='/start' activeClassName='clickedNavigation'>
            <ListItem button>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Strona główna"/>
            </ListItem>
        </NavLink>
        <NavLink to='/wybieraczki' activeClassName='clickedNavigation'>
            <ListItem button>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Wybieraczki"/>
            </ListItem>
        </NavLink>
        {role === 'admin' ?
            <NavLink to='/uzytkownicy' activeClassName='clickedNavigation'>
                <ListItem button>
                    <ListItemIcon>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Użytkownicy"/>
                </ListItem>
            </NavLink>
            : ''
        }
        {role === 'admin' ?
            <NavLink to='/galeria' activeClassName='clickedNavigation'>
                <ListItem button>
                    <ListItemIcon>
                        <PhotoLibrary/>
                    </ListItemIcon>
                    <ListItemText primary="Baza obrazów"/>
                </ListItem>
            </NavLink>
            : ''
        }
        {/*<NavLink to='/statystyki' activeClassName='clickedNavigation'>*/}
        {/*<ListItem button>*/}
        {/*<ListItemIcon>*/}
        {/*<BarChartIcon/>*/}
        {/*</ListItemIcon>*/}
        {/*<ListItemText primary="Statystyki"/>*/}
        {/*</ListItem>*/}
        {/*</NavLink>*/}
        <NavLink to='/aplikacjaMobilna' activeClassName='clickedNavigation'>
            <ListItem button>
                <ListItemIcon>
                    <SmartphoneIcon/>
                </ListItemIcon>
                <ListItemText primary="Aplikacja mobilna"/>
            </ListItem>
        </NavLink>
        {/*<ListItem button>*/}
        {/*<ListItemIcon>*/}
        {/*<LayersIcon/>*/}
        {/*</ListItemIcon>*/}
        {/*<ListItemText primary="Integrations"/>*/}
        {/*</ListItem>*/}
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Saved reports</ListSubheader>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Current month"/>
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Last quarter"/>
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Year-end sale"/>
        </ListItem>
    </div>
);
