import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import {compose} from "redux";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import SwipeableViews from 'react-swipeable-views';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";

import FormControl from "@material-ui/core/FormControl/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        margin: -theme.spacing.unit * 3,
    },
});


class ProductsNewColumnDialog extends React.Component {
    state = {
        open: false,
        name: '',
        type: 0,
        options: '',
        eanValue: "product_name"
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.open === false && props.columnToUpdate !== null) {
            // Options parsing
            let optionsString = "";
            if (props.columnToUpdate.columnType === 1) {
                props.columnToUpdate.options.map((option) => {
                    optionsString += option.title + '\n'
                });
                console.log(optionsString);
            }

            return {
                open: true,
                name: props.columnToUpdate.name,
                type: props.columnToUpdate.columnType,
                options: optionsString,
                eanValue: props.columnToUpdate.key
            }
        }
        return null
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
        if (this.props.columnToUpdate === null)
            this.setState({
                name: '',
                type: 0,
                options: '',
                eanValue: ""
            })
    };

    handleClose = () => {
        this.props.handleEditClose();
        this.setState({open: false});
    };

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    };

    handleOptionsChange = (event) => {
        this.setState({options: event.target.value});
    };

    handleEanChange = event => {
        this.setState({eanValue: event.target.value});
    };

    handleTabChange = (event, type) => {
        this.setState({type});
    };

    handleChangeIndex = index => {
        this.setState({type: index});
    };

    handleCreate = () => {
        console.log("!!!!!!!!!!!!!!!!!");

        let optionsParsed = this.state.options.split('\n')
            .filter(item => item !== "")
            .map((item, key) => {
                return ({
                    id: key,
                    title: item
                });
            });
        // const isInEditingMode = this.props.columnToUpdate !== null;
        const success = this.props.handleColumnAdd(this.state.type, this.state.name, optionsParsed, this.state.eanValue, this.props.columnToUpdate);
        if (success)
            this.handleClose()
    };

    handleDelete = () => {
        this.props.handleColumnDelete(this.props.columnToUpdate.key);
    };

    render() {
        const {classes, theme, className, columnToUpdate} = this.props;
        return (
            <React.Fragment>
                <Button className={className} variant="extendedFab" color={"primary"} onClick={this.handleClickOpen}>
                    <AddIcon/>
                    Dodaj cechę
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Utwórz nową kolumnę z cechą produktu</DialogTitle>
                    <DialogContent>
                        {
                            columnToUpdate === null ? (
                                <Tabs
                                    value={this.state.type}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    fullWidth
                                >
                                    <Tab label="EAN"/>
                                    <Tab label="Jedna z opcji"/>
                                    <Tab label="Dowolny tekst"/>
                                </Tabs>
                            ) : (
                                ""
                            )
                        }
                        <TextField
                            margin="dense"
                            id="name"
                            label="Nazwa kolumny"
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            fullWidth
                        />
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={this.state.type}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <React.Fragment>
                                <DialogContentText>
                                    Wskaż kolumnę z bazy EAN. <br/>
                                    (można użyć również dla produktów nie istniejących w bazie)
                                </DialogContentText>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Ean"
                                        name="eanColumn"
                                        // className={classes.group}
                                        value={this.state.eanValue}
                                        onChange={this.handleEanChange}
                                    >
                                        {/*<FormControlLabel value="product_name" control={<Radio/>}*/}
                                        {/*label="Nazwa produktu"/>*/}
                                        {/*<FormControlLabel value="ean" control={<Radio/>} label="Numer EAN"/>*/}
                                        <FormControlLabel value="brand_name" control={<Radio/>} label="Producent"/>
                                        <FormControlLabel value="search_price" control={<Radio/>} label="Cena"/>
                                        {/*<FormControlLabel value="merchant_deep_link" control={<Radio/>} label="Link"/>*/}
                                        {/*<FormControlLabel value="merchant_image_url" control={<Radio/>}*/}
                                        {/*label="Zdjęcie"/>*/}
                                    </RadioGroup>
                                </FormControl>
                            </React.Fragment>
                            <React.Fragment>
                                <DialogContentText>
                                    Podaj każdą opcję w oddzielnej linii.
                                </DialogContentText>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Opcje"
                                    multiline
                                    fullWidth
                                    rows="6"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.options}
                                    onChange={this.handleOptionsChange}
                                />
                            </React.Fragment>
                            <React.Fragment>
                                <DialogContentText>

                                </DialogContentText>
                            </React.Fragment>
                        </SwipeableViews>

                    </DialogContent>
                    <DialogActions>
                        {columnToUpdate !== null ? (
                            <Button onClick={this.handleDelete} color="secondary">
                                Usuń
                            </Button>
                        ) : ("")}
                        <Button onClick={this.handleClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={this.handleCreate} color="primary">
                            Zapisz
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ProductsNewColumnDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, {withTheme: true})
)(ProductsNewColumnDialog)