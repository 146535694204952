import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const LoadingScreen = () => {
    return (
        <div>
            <CircularProgress mode="indeterminate" size={80} />
        </div>
    );
};

export default LoadingScreen;