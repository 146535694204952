import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Select from "react-select";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
});

class NewMobileAppChooserDialog extends Component {
    state = {
        open: false,
        wybieraczka: ""
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleChoosersListUpdate = () => {
        const docRef = this.props.firestore.collection("mobileApp").doc("categories");

// Atomically add a new region to the "regions" array field.
        docRef.update({
            [`${this.props.category}.choosers`]: this.props.firebase.firestore.FieldValue.arrayUnion(this.state.wybieraczka)
        })
            .then(() => {
                this.props.enqueueSnackbar("Dodano wybieraczkę do kategorii!", {
                    variant: 'success',
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Wystąpił błąd --- " + error, {
                    variant: 'error',
                })
            });

        this.handleClose();
    };

    render() {
        const {classes, users, wybieraczki} = this.props;


        const wybieraczkiSelectOptions = [];
        if (wybieraczki)
            for (const wybieraczkaId in wybieraczki) {
                wybieraczkiSelectOptions.push({value: wybieraczkaId, label: wybieraczki[wybieraczkaId].name});
            }

        return (
            <div>
                <Fab variant="extended" color={"primary"} onClick={this.handleOpen}>
                    <AddIcon/>
                    Dodaj wybieraczkę
                </Fab>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Utwórz nowy eksport dla użytkownika:</DialogTitle>
                    <DialogContent>
                        <div style={{width: "300px", margin: "auto", marginBottom: "30px", height: "500px"}}>
                            <Select
                                value={this.state.wybieraczka}
                                // defaultValue={values.creator}
                                onChange={(value) => {
                                    this.setState({wybieraczka: value});
                                }}
                                options={wybieraczkiSelectOptions}
                                // styles={{container: () => ({width: "300px", margin: "auto"})}}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={this.handleChoosersListUpdate} color="primary">
                            Zapisz
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

NewMobileAppChooserDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.data.wybieraczki,
        // mobileApp: state.firestore.ordered.mobileApp.categories,
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(NewMobileAppChooserDialog)