import {compose} from 'redux'
import {connect} from 'react-redux'
import {firebaseConnect, getVal, isEmpty, isLoaded} from 'react-redux-firebase'
import React from "react";

const Post = ({firebase, eanProduct, ean, onProductFound}) => {
    if(ean.length > 5) {
        if (!isLoaded(eanProduct)) {
            return <div>Szukam...</div>
        }
        if (isEmpty(eanProduct)) {
            return <div>Nie znaleziono produktu.</div>
        }
        onProductFound(eanProduct);
        return (
            <div>
                <h1>Znaleziono produkt:</h1>
                <div>
                    {JSON.stringify(eanProduct, null, 2)}
                </div>
            </div>
        )
    }
    return <div>Podaj numer ean.</div>
};

export default compose(
    firebaseConnect((props) => [
        { path: 'produkty', queryParams: ['parsed', 'orderByChild=ean', 'limitToFirst=1', `equalTo=${props.ean}`]} // string equivalent 'todos'
    ]),
    connect((state) => ({
        eanProduct: state.firebase.data.produkty,
    }))
)(Post)