import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IframeDialogContent from "./IframeDialogContent";
import {compose} from "redux";
import {withFirestore} from "react-redux-firebase";
import {withSnackbar} from "notistack";

export const newIframeTemplate = {
    title: "",
    description: "",
    imageLink: "",
    buttonLink: ""
};

class IframeDialog extends Component {
    state = {
        open: false,
    };

    addIframe = () => {
        const newDocRef = this.props.firestore.collection("iframe").doc();
        const chooserDocRef = this.props.firestore.collection("wybieraczki").doc(this.props.chooserId);

        this.props.firestore.runTransaction(t => {
            return t.get(newDocRef)
                .then(doc => {
                    newIframeTemplate.buttonLink = "http://app.wybierator.pl/" + this.props.chooserId + "/" + this.props.exportUser.value;
                    t.set(newDocRef, newIframeTemplate);

                    // console.log({...this.props.exportUser, iframe: newDocRef.id});

                    t.update(chooserDocRef, {
                        exportUsers: this.props.firebase.firestore.FieldValue.arrayRemove(this.props.exportUser)
                    });

                    t.update(chooserDocRef, {
                        exportUsers: this.props.firebase.firestore.FieldValue.arrayUnion({
                            ...this.props.exportUser,
                            iframe: newDocRef.id
                        })
                    })
                });
        })
            .then(result => {
                this.props.enqueueSnackbar("Utworzono iframe!", {
                    variant: 'success',
                });
                // this.props.history.push(`/wybieraczki/${newDocRef.id}`);
            }).catch(err => {
            console.log('Transaction failure:', err);
            this.props.enqueueSnackbar("Coś poszło nie tak... :( = " + err, {
                variant: 'error',
            })
        });
    };

    handleOpen = () => {
        this.setState({open: true});
        if (!this.props.iframeId)
            this.addIframe();
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, iframeId} = this.props;

        if (this.props.exportUser.value !== "allUsers")
            return (
                <React.Fragment>
                    <Button variant="contained" color={"primary"} onClick={this.handleOpen}>
                        {!iframeId ? "Dodaj iframe" : "Edytuj iframe"}
                    </Button>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth={false}
                    >
                        <IframeDialogContent iframeId={iframeId} exportUser={this.props.exportUser}/>
                    </Dialog>
                </React.Fragment>
            );
        else
            return (<></>);
    }
}

export default compose(
    withFirestore,
    withSnackbar,
    // withStyles(styles, {withTheme: true}),
)(IframeDialog)