import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import StatisticsChart from "./StatisticsChart";
import Typography from "@material-ui/core/Typography/Typography";
import StatisticsProductsTable from "./StatisticsProductsTable";
import DateFnsUtils from '@date-io/date-fns';
import {InlineDatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const headers = [
    {label: "First Name", key: "firstname"},
    {label: "Last Name", key: "lastname"},
    {label: "Email", key: "email"}
];

const data = [
    {firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com"},
    {firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com"},
    {firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com"}
];


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    statsGridItem: {
        margin: "10px",
    }
});

class Statistics extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const weekAgo = new Date();
        weekAgo.setDate(today.getDate() - 7);

        this.state = {
            dateFrom: weekAgo,
            dateTo: today,
            userToStats: ""
        }
    }

    handleUserToStatsChange = event => {
        this.setState({userToStats: event.target.value});
    };

    onChangeDateFrom = (date) => {
        console.log('Date: ', date);
        this.setState({dateFrom: date});
    };
    onChangeDateTo = (date) => {
        console.log('Date: ', date);
        this.setState({dateTo: date});
    };


    render() {
        const {classes, chooserData, chooserStatistics, users} = this.props;
        const exports = chooserData.exportUsers ? chooserData.exportUsers : [];

        // console.log(chooserStatistics);

        return (
            <React.Fragment>
                <div>
                    <Typography variant={"h4"} gutterBottom>
                        Suma wyświetleń:
                    </Typography>
                    <Typography variant={"h1"} gutterBottom>
                        {chooserData.viewsCounter}
                    </Typography>
                </div>
                <div style={{marginTop: "100px"}}>
                    <Typography variant={"h4"} gutterBottom>
                        Statystyki:
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                        <Grid container justify={"center"} alignItems="center">
                            <Grid item className={classes.statsGridItem}>
                                <Typography variant={"h5"} noWrap>
                                    Od:
                                </Typography>
                                <InlineDatePicker onlyCalendar value={this.state.dateFrom}
                                                  onChange={this.onChangeDateFrom}/>
                            </Grid>
                            <Grid item className={classes.statsGridItem}>
                                <Typography variant={"h5"} noWrap>
                                    Do:
                                </Typography>
                                <InlineDatePicker onlyCalendar value={this.state.dateTo}
                                                  onChange={this.onChangeDateTo}/>
                            </Grid>
                            <Grid item className={classes.statsGridItem}>
                                <Typography variant={"h5"} noWrap>
                                    Kto:
                                </Typography>
                                <FormControl>
                                    <Select
                                        value={this.state.userToStats}
                                        onChange={this.handleUserToStatsChange}
                                        displayEmpty
                                        name="usertostats"
                                        // className={classes.selectEmpty}
                                    >
                                        <MenuItem value="">
                                            <em>Wszyscy</em>
                                        </MenuItem>
                                        {chooserData.exportUsers &&
                                        chooserData.exportUsers.map((userObject) => {
                                            if (userObject.value !== "allUsers")
                                                return (
                                                    <MenuItem
                                                        value={userObject.value}
                                                        key={userObject.value}>{userObject.label}</MenuItem>
                                                )
                                        })}
                                    </Select>
                                </FormControl>
                                {/*exports*/}
                            </Grid>

                        </Grid>
                    </MuiPickersUtilsProvider>

                    <StatisticsChart data={chooserStatistics} users={users} dateFrom={this.state.dateFrom}
                                     dateTo={this.state.dateTo} exports={exports} userToStats={this.state.userToStats}
                                     chooserName={chooserData.name}/>

                </div>
                <div style={{marginTop: "100px"}}>
                    <Typography variant={"h4"} gutterBottom>
                        Kliknięcia w produkty:
                    </Typography>
                    <StatisticsProductsTable products={chooserData.productsRows} chooserName={chooserData.name}/>
                </div>
            </React.Fragment>
        );
    }
}

Statistics.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Statistics);
