import React from 'react';
import {SortableElement, SortableHandle,} from 'react-sortable-hoc';
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField/TextField";
import ImageRenderer from "../../../ImageRenderer";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Button from "@material-ui/core/Button/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Grid from "@material-ui/core/Grid/Grid";

const DragHandle = SortableHandle(() => <span><DragIndicatorIcon/> </span>); // This can be any component you want

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        //flexBasis: '33.33%',
        flexShrink: 0,
    },
    formControl: {
        marginBottom: theme.spacing.unit * 2,
    }
});

const QuestionsSingleItem = SortableElement(({value, expanded, classes, chooserData, questions, functionsPack, imageDialogOpen}) => {
    return (
        <div>
            {
                // questions.map((value, key) => {
                //     return (
                <ExpansionPanel expanded={expanded === value.id} onChange={functionsPack.handleChange(value.id)}
                                key={value.id} style={{marginBottom: "5px"}}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}> <DragHandle/>
                            {value.question}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <Grid container>
                                <Grid item xs={6} style={{textAlign: "left"}}>
                                    <TextField
                                        label="Pytanie"
                                        onChange={(event) => {
                                            functionsPack.handleNameChange(event, value)
                                        }}
                                        value={value.question}
                                        margin="dense"
                                        id="question"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.formControl}
                                    />

                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel
                                            // ref={ref => {
                                            //     this.InputLabelRef = ref;
                                            // }}
                                            htmlFor="outlined-age-simple"
                                        >
                                            Kolumna
                                        </InputLabel>
                                        <Select
                                            value={value.column}
                                            onChange={(event) => {
                                                functionsPack.handleColumnChange(event, value)
                                            }}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={63}
                                                    name="age"
                                                    id="outlined-age-simple"
                                                />
                                            }
                                            style={{minWidth: "400px"}}
                                        >
                                            <MenuItem value="">
                                                <em>Brak</em>
                                            </MenuItem>
                                            {
                                                chooserData.productsColumns.map((value) => {
                                                    if (value.columnType === 1)
                                                        return (
                                                            <MenuItem value={value.key}
                                                                      key={value.key}>{value.name}</MenuItem>
                                                        );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <Typography variant={"caption"}>
                                        * Tutaj widoczne są tylko kolumny utworzone w trybie "jedna z opcji"
                                    </Typography>

                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="extendedFab" color={"primary"}
                                            onClick={() => functionsPack.handleImageDialogOpen(true, value)}
                                            className={classes.button}>
                                        Załaduj obrazek
                                    </Button>
                                    <br/>
                                    <ImageRenderer url={value.imageUrl} key={value.imageUrl}/>
                                </Grid>

                                <Button variant="extendedFab" color={"secondary"}
                                        onClick={() => functionsPack.handleQuestionDelete(value.id)}
                                        style={{display: "block", margin: "20px"}}>
                                    {/*<AddIcon/>*/}
                                    Usuń pytanie
                                </Button>
                            </Grid>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                //     );
                // })
            }

        </div>
    );
});

QuestionsSingleItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles)
)(QuestionsSingleItem)