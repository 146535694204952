import React, {Component} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import IframeForm from "./IframeForm";
import {Divider, withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import LoadingScreen from "../../../../Login/LoadingScreen";
import {compose} from "redux";
import {connect} from "react-redux";
import {newIframeTemplate} from "./IframeDialog";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    form: {
        padding: "30px",
        display: "grid"
    },
    right: {
        justifySelf: "end",
        width: "120px",
        marginTop: "20px"
    }
});

class IframeDialogContent extends Component {
    state = newIframeTemplate;

    loadFirebaseDataToStateOnce = () => {
        this.setState(this.props.iframe[this.props.iframeId]);
        this.loadFirebaseDataToStateOnce = () => {
        };
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
        // console.log(e.target.name)
    };

    handleImageChange = (newLink) => {
        this.setState({imageLink: newLink});
    };

    renderLoading = () => {
        return (
            <div style={{margin: "100px"}}>
                {/*<div className={this.props.classes.appBarSpacer}/>*/}
                <LoadingScreen/>
            </div>);
    };

    updateIframe = () => {
        this.props.firestore.update({collection: 'iframe', doc: this.props.iframeId}, this.state)
            .then(() => {
                this.props.enqueueSnackbar("Konfiguracja iframe zapisana!", {
                    variant: 'success',
                });
            })
            .catch(() => {
                this.props.enqueueSnackbar("Wystąpił błąd - zmiany nie zostały zapisane... :(", {
                    variant: 'error',
                })
            });
    };

    renderDialog = () => {
        const {classes} = this.props;

        return (
            <>
                <DialogTitle>Edytor iframe:</DialogTitle>

                <div className={classes.form}>
                    <IframeForm handleChange={this.handleChange} handleImageChange={this.handleImageChange}
                                formData={this.state}/>
                    <Button
                        onClick={this.updateIframe}
                        variant="contained"
                        color="secondary"
                        className={classes.right}>
                        Zapisz
                    </Button>
                </div>

                <Divider style={{margin: "50px"}}/>

                <iframe
                    src={`http://iframe.wybierator.pl/${this.props.iframeId}`}
                    // src={`https://wybierator-iframe.surge.sh/?id=${this.props.match.params.id}/${this.props.exportUser.value}`}
                    width="700" height="400"/>

            </>
        )
    };

    render() {
        const {classes, iframe, iframeId} = this.props;

        console.log(iframeId);
        console.log(iframe);

        if (!iframeId)
            return (this.renderLoading());
        else {
            if (isLoaded(iframe) && iframe[iframeId]) {
                this.loadFirebaseDataToStateOnce();
                return (this.renderDialog());
            } else {
                return (this.renderLoading());
            }
        }
    }
}

export default compose(
    firestoreConnect(props => [
        {collection: 'iframe', doc: props.iframeId},
    ]),
    connect((state) => ({
        iframe: state.firestore.data.iframe
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(IframeDialogContent)