import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import ImageSelectDialog from "../ImageSelectDialog";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

class IframeForm extends Component {
    state = {
        isImageChooserDialogOpen: false,
    };

    render() {
        return (
            <>
                <TextField
                    id="outlined-name"
                    label="Tytuł"
                    className={this.props.classes.textField}
                    name={"title"}
                    value={this.props.formData.title}
                    onChange={this.props.handleChange}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    id="outlined-name"
                    label="Opis"
                    className={this.props.classes.textField}
                    name={"description"}
                    value={this.props.formData.description}
                    onChange={this.props.handleChange}
                    margin="normal"
                    variant="outlined"
                    multiline
                />
                <TextField
                    id="outlined-name"
                    label="Obrazek"
                    className={this.props.classes.textField}
                    name={"imageLink"}
                    value={this.props.formData.imageLink}
                    onChange={this.props.handleChange}
                    margin="normal"
                    variant="outlined"
                />
                <Button variant="outlined" color={"primary"}
                        onClick={() => this.setState({isImageChooserDialogOpen: true})
                        }>
                    Załaduj obrazek
                </Button>
                <ImageSelectDialog isOpen={this.state.isImageChooserDialogOpen}
                                   handleClose={() => this.setState({isImageChooserDialogOpen: false})}
                                   imageUrl={this.props.formData.imageLink}
                                   handleSave={(newImageUrl) => {
                                       this.props.handleImageChange(newImageUrl);
                                       this.setState({isImageChooserDialogOpen: false})
                                   }}
                />

            </>
        );
    }
}


export default withStyles(styles)(IframeForm);