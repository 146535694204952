import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import LoginForm from "./LoginForm";
import {withSnackbar} from "notistack";


class Login extends Component {

    loginAction = (email, password, event) => {
        event.preventDefault();
        this.props.firebase.login({
            email: email,
            password: password
        })
            .then(() => {
                this.props.enqueueSnackbar("Zalogowano!", {
                    variant: 'success',
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Błąd logowania --- " + error, {
                    variant: 'error',
                })
            });
    };

    logoutAction = () => {
        this.props.firebase.logout()
    };

    render() {
        return (
            <React.Fragment>
                <LoginForm onSubmit={this.loginAction}/>
            </React.Fragment>
        );
    }
}


export default compose(
    // UserIsNotAuthenticated,
    // withRouter(connect(mapStateToProps)),
    withSnackbar,
    firestoreConnect(), // or { collection: 'todos' }
    connect((state) => ({
        // wybieraczki: state.firestore.ordered.wybieraczki
        profile: state.firebase.profile, // pass profile data as this.props.profile
        auth: state.firebase.auth // pass auth data as this.props.auth
    }))
)(Login);
