import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const SimpleStatCard = (props) => (
    <div>
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="h1">
                    {props.number}
                </Typography>
            </CardContent>
        </Card>
    </div>
);

SimpleStatCard.propTypes = {};

export default SimpleStatCard;