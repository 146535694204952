import React, {Component} from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper/Paper";
import ReactDataGrid from 'react-data-grid/dist/react-data-grid.min.js';
import update from 'immutability-helper';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Button from "@material-ui/core/Button/Button";
import ProductsNewColumnDialog from "./ProductsNewColumnDialog";
import {createColumnsEditors, CustomColumnHeader} from "./tools";
import ImageSelectDialog from "./ImageSelectDialog";
import {Data, Draggable, DraggableHeader, Editors, Formatters} from 'react-data-grid-addons';
import ProductsNewRowEanDialog from "./ProductsNewRowEanDialog";
import {withSnackbar} from "notistack";

const {DraggableContainer: aaa} = DraggableHeader;
const DraggableContainer2 = aaa;
const {Container: DraggableContainer, RowActionsCell, DropTargetRowContainer} = Draggable;

const {AutoComplete: AutoCompleteEditor} = Editors;
const {Selectors} = Data;
const {ImageFormatter} = Formatters;


const RowRenderer = DropTargetRowContainer(ReactDataGrid.Row);


// const {
//     Draggable: { Container: DraggableContainer, RowActionsCell, DropTargetRowContainer },
//     Data: { Selectors }
// } =

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        margin: theme.spacing.unit * 2
    },
});

class Products extends Component {
    static defaultProps = {rowKey: 'id'};

    constructor(props) {
        super(props);
        this.state = {
            columns: createColumnsEditors(props.chooserData.productsColumns, this.openColumnEditDialog),
            rows: props.chooserData.productsRows,
            selectedIds: [],
            isImageSelectDialogOpen: false,
            columnToUpdate: null,
            selectedImageRow: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        // Unimportant and not working fix for forgetting product statistics after products save
        // for (let i = 0; i < state.rows.length; i++) {
        //     if (props.chooserData.productsRows[i].clicksCounter !== state.rows[i].clicksCounter) {
        //         const newRows = state.rows;
        //         newRows[i].clicksCounter = props.chooserData.clicksCounter;
        //         return {
        //             rows: newRows
        //         }
        //     }
        // }
        return null;
    }

    //======================== ROWS DRAGING MATHODS ================================
    isDraggedRowSelected = (selectedRows, rowDragSource) => {
        if (selectedRows && selectedRows.length > 0) {
            let key = this.props.rowKey;
            return selectedRows.filter(r => r[key] === rowDragSource.data[key]).length > 0;
        }
        return false;
    };

    reorderRows = (e) => {
        let selectedRows = Selectors.getSelectedRowsByKey({
            rowKey: this.props.rowKey,
            selectedKeys: this.state.selectedIds,
            rows: this.state.rows
        });
        let draggedRows = this.isDraggedRowSelected(selectedRows, e.rowSource) ? selectedRows : [e.rowSource.data];
        let undraggedRows = this.state.rows.filter(function (r) {
            return draggedRows.indexOf(r) === -1;
        });
        let args = [e.rowTarget.idx, 0].concat(draggedRows);
        Array.prototype.splice.apply(undraggedRows, args);
        this.setState({rows: undraggedRows});
        this.onDataGridTouch();
    };

    reorderRowsWithButton = (rowId, indexesToMove) => {
        // let selectedRows = Selectors.getSelectedRowsByKey({
        //     rowKey: this.props.rowKey,
        //     selectedKeys: this.state.selectedIds,
        //     rows: this.state.rows
        // });
        // let draggedRows = this.isDraggedRowSelected(selectedRows, e.rowSource) ? selectedRows : [e.rowSource.data];
        // let undraggedRows = this.state.rows.filter(function (r) {
        //     return draggedRows.indexOf(r) === -1;
        // });

        const newRows = this.state.rows.slice();

        newRows[rowId] = this.state.rows[rowId + indexesToMove];
        newRows[rowId + indexesToMove] = this.state.rows[rowId];

        this.setState({rows: newRows});
        this.onDataGridTouch();
    };

    onRowsSelected = (rows) => {
        this.setState({selectedIds: this.state.selectedIds.concat(rows.map(r => r.row[this.props.rowKey]))});
    };

    onRowsDeselected = (rows) => {
        let rowIds = rows.map(r => r.row[this.props.rowKey]);
        this.setState({selectedIds: this.state.selectedIds.filter(i => rowIds.indexOf(i) === -1)});
    };

    handleGridRowsUpdated = ({fromRow, toRow, updated}) => {
        let rows = this.state.rows;

        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = rows[i];
            rows[i] = update(rowToUpdate, {$merge: updated});
        }

        this.setState({rows});
        this.onDataGridTouch();
    };

    onHeaderDrop = (source, target) => {
        const stateCopy = Object.assign({}, this.state);
        const columnSourceIndex = this.state.columns.findIndex(
            i => i.key === source
        );
        const columnTargetIndex = this.state.columns.findIndex(
            i => i.key === target
        );

        stateCopy.columns.splice(
            columnTargetIndex,
            0,
            stateCopy.columns.splice(columnSourceIndex, 1)[0]
        );

        const emptyColumns = Object.assign({}, this.state, {columns: []});
        this.setState(
            emptyColumns, () => {
                const reorderedColumns = Object.assign({}, this.state, {columns: stateCopy.columns});
                this.setState(
                    reorderedColumns
                );
            });
    };

    //========================== ROW & COLUMNS ==============================
    handleRowAdd = (rowData) => {
        let rows = this.state.rows;
        if (typeof rowData === 'undefined') {
            rowData = {};
        }
        rowData.id = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
        rows.push(rowData);

        this.setState({rows});
        this.onDataGridTouch();
    };

    handleRowDelete = (e, {rowIdx}) => {
        const newRows = this.state.rows;
        this.setState({rows: newRows.splice(rowIdx, 1)});
        this.onDataGridTouch();
    };

    handleSelectedRowsDelete = () => {
        const newRows = this.state.rows.filter((row) => (!this.state.selectedIds.includes(row.id)));
        this.setState({rows: newRows});
        this.onDataGridTouch();
    };

    handleUpdateRowImage = (url) => {
        const rowIdx = this.state.rows.findIndex((e) => e === this.state.selectedImageRow);
        const newRows = this.state.rows;
        newRows[rowIdx].merchant_image_url = url;
        const columns = this.state.columns;
        columns.push({key: "refresher"});
        this.setState({
            rows: newRows,
            columns: columns,
            isImageSelectDialogOpen: false
        });
        this.onDataGridTouch();
    };

    handleColumnAdd = (columnType, columnName, columnOptions, eanValue, columnToUpdate, isAutomatic) => {
        let columns = this.state.columns;

        const PrioritiesEditor = <AutoCompleteEditor options={columnOptions}/>;
        const typesSwitch = (typeId) => ({
            0: ["editable", true],  //ean
            1: ["editor", PrioritiesEditor],    //oneOf
            2: ["editable", true],  //text
        })[typeId];
        const columnTypeTransformed = typesSwitch(columnType);

        let found = -1;
        const isInEditingMode = columnToUpdate !== null && typeof columnToUpdate !== 'undefined';
        if (isInEditingMode)
            found = columns.findIndex((column) => {
                return column.key === columnToUpdate.key;
            });
        else
            found = columns.findIndex((column) => {
                return column.key === columnName.toLowerCase() || (column.key === eanValue && columnType === 0);
            });

        if (isInEditingMode) {
            columns[found] =
                {
                    key: columns[found].key,
                    name: columnName,
                    [columnTypeTransformed[0]]: columnTypeTransformed[1],
                    options: columnOptions,
                    columnType: columnType,
                    resizable: true,
                    draggable: true,
                    headerRenderer: <CustomColumnHeader openColumnEditDialog={this.openColumnEditDialog}/>,
                    formatter: eanValue === 'merchant_image_url' ? ImageFormatter : '',
                    width: eanValue === 'merchant_image_url' ? 100 : 200,
                    frozen: columnName === "Nazwa"
                };
            columns.push({key: "refresher"});
            this.setState({columns});
            this.onDataGridTouch();
            return true;
        } else if (found === -1) {
            columns.push(
                {
                    key: columnType === 0 ? eanValue : columnName.toLowerCase(),
                    name: columnName,
                    [columnTypeTransformed[0]]: columnTypeTransformed[1],
                    options: columnOptions,
                    columnType: columnType,
                    resizable: true,
                    draggable: true,
                    headerRenderer: <CustomColumnHeader openColumnEditDialog={this.openColumnEditDialog}/>,
                    formatter: eanValue === 'merchant_image_url' ? ImageFormatter : '',
                    width: eanValue === 'merchant_image_url' ? 100 : 200,
                    frozen: columnName === "Nazwa"
                }
            );
            this.setState({columns});
            this.onDataGridTouch();
            // this.props.onSubmit(this.state.columns, this.state.rows, this.state.options);
            return true;
        } else {
            if (!isAutomatic)
                this.props.enqueueSnackbar("Taka kolumna już istnieje :(", {
                    variant: 'error',
                });
            return false;
        }
    };

    handleColumnDelete = (rowIdx) => {
        let newColumns = this.state.columns;
        newColumns.splice(rowIdx, 1);
        this.setState({columns: newColumns});
        this.onDataGridTouch();
    };

    openColumnEditDialog = (columnKey) => {
        const columnToUpdate = this.state.columns.find((column) => (column.key === columnKey));
        this.setState({columnToUpdate: columnToUpdate});
    };

    getCellActions(column, row) {
        const rowIndex = this.state.rows.findIndex((item) => (row === item));
        if (column.key === 'merchant_image_url') {
            return [
                {
                    icon: <AddIcon/>,
                    callback: () => {
                        this.setState({
                            isImageSelectDialogOpen: true,
                            selectedImageRow: row
                        });
                    }
                }
            ]
        } else if (column.key === 'reorder_buttons') {
            return [
                {
                    icon: <div><ArrowDownward/><br/></div>,
                    callback: () => {
                        this.reorderRowsWithButton(rowIndex, 1)
                    }
                },
                {
                    icon: <div><ArrowUpward/><br/></div>,
                    callback: () => {
                        // alert("Deleting");
                        this.reorderRowsWithButton(rowIndex, -1)
                    }
                },
            ]
        }
    };

    onDataGridTouch() {
        this.props.onTouch(true);
    }

    componentDidUpdate(prevProps, prevState) {
        const {chooserData} = this.props;
        const columns = this.state.columns;
        let found;

        this.handleColumnAdd(0, "Nazwa", "", "product_name", null, true);
        this.handleColumnAdd(0, "Zdjęcie", "", "merchant_image_url", null, true);

        if (chooserData.resultType === "link" || chooserData.resultType === "dataForm") {
            found = this.state.columns.findIndex((column) => (column.key === "ean"));

            if (found !== -1)
                this.handleColumnDelete(found);
            this.handleColumnAdd(0, "Link", "", "merchant_deep_link", null, true);
        }
        if (chooserData.resultType === "buybox") {
            found = this.state.columns.findIndex((column) => (column.key === "merchant_deep_link"));

            if (found !== -1)
                this.handleColumnDelete(found);
            this.handleColumnAdd(0, "Kod EAN", "", "ean", null, true);
        }

        if (typeof columns[columns.length - 1] !== "undefined") {
            if (columns[columns.length - 1].key === "refresher" || found !== -1) {
                columns.pop();
                this.setState({columns});
            }
        }
    }

    //=========================== RENDER =============================
    render() {
        const {classes, onSubmit, chooserData} = this.props;

        // handleColumnAdd = (columnType, columnName, columnOptions, eanValue)
        // const columnsToRender = JSON.parse(JSON.stringify(this.state.columns));
        const columnsToRender = this.state.columns.filter((row) => (true)); // strange method to copy array

        columnsToRender.splice(1, 0, {
            key: "reorder_buttons", Name: "Kolejność", width: 80,
            // formatter: (value) => <div style={{width: "0px", left: "-50px"}}>0</div>
        });


        this.rowGetter = (i) => {
            return this.state.rows[i];
        };
        return (
            <Paper className={classes.paper}>
                <Button variant="extendedFab" color={"primary"}
                        onClick={() => this.handleSelectedRowsDelete()}
                        disabled={this.state.selectedIds.length === 0}
                        className={classes.button}>
                    <Delete/>
                    Usuń zaznaczone
                </Button>
                <ImageSelectDialog  //invisible on start
                    isOpen={this.state.isImageSelectDialogOpen}
                    handleClose={() => (this.setState({isImageSelectDialogOpen: false}))}
                    handleSave={(url) => this.handleUpdateRowImage(url)}
                    imageUrl={this.state.selectedImageRow !== null ? this.state.selectedImageRow.merchant_image_url : ""}
                />
                <Button variant="extendedFab" color={"primary"} onClick={() => this.handleRowAdd()}
                        className={classes.button}>
                    <AddIcon/>
                    Dodaj produkt
                </Button>
                <ProductsNewRowEanDialog
                    className={classes.button}
                    handleRowAdd={this.handleRowAdd}/>
                <ProductsNewColumnDialog
                    className={classes.button}
                    handleColumnAdd={this.handleColumnAdd}
                    columnToUpdate={this.state.columnToUpdate}
                    handleEditClose={() => {
                        this.setState({columnToUpdate: null})
                    }}
                    handleColumnDelete={(columnKey) => {
                        const columnId = this.state.columns.findIndex((column) => (column.key === columnKey));
                        this.handleColumnDelete(columnId);
                    }}
                />
                <Button className={classes.button} variant="extendedFab" color={"secondary"} onClick={() => {
                    onSubmit(this.state.columns, this.state.rows, this.state.options)
                }}>
                    Zapisz
                </Button>

                <div>

                    {/*<DraggableContainer>*/}
                    <DraggableContainer2 onHeaderDrop={this.onHeaderDrop}>
                        <ReactDataGrid
                            enableCellSelect={true}
                            // rowActionsCell={RowActionsCell}
                            columns={columnsToRender}
                            rowGetter={this.rowGetter}
                            rowsCount={this.state.rows.length}
                            minHeight={500}
                            // width={1000}
                            rowHeight={55}
                            // rowRenderer={<RowRenderer onRowDrop={this.reorderRows}/>} //
                            onGridRowsUpdated={this.handleGridRowsUpdated}
                            rowSelection={{
                                showCheckbox: true,
                                enableShiftSelect: true,
                                onRowsSelected: this.onRowsSelected,
                                onRowsDeselected: this.onRowsDeselected,
                                selectBy: {
                                    keys: {rowKey: this.props.rowKey, values: this.state.selectedIds}
                                }
                            }}
                            getCellActions={(column, row) => this.getCellActions(column, row)}
                            onColumnResize={(idx, width) => {
                                let stateColIndex = 0;
                                if (idx > 2)
                                    stateColIndex = idx - 2;
                                this.setState((prevState) => {
                                    prevState.columns[stateColIndex].width = width;
                                    return {
                                        columns: prevState.columns
                                    }
                                })
                            }}
                            // key={columnsToRender.length}
                        />
                    </DraggableContainer2>
                </div>
                {/*</DraggableContainer>*/}
            </Paper>
        );
    }
}

Products.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    withSnackbar
)(Products)