import React, {Component} from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from "@material-ui/core/Dialog/Dialog";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import PropTypes from "prop-types";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";

const styles = theme => ({
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

class CreateUserDialog extends Component {
    state = {
        open: false,
        email: "",
        username: "",
        pass: (Date.now().toString(36) + Math.random().toString(36).substr(2, 6)).toUpperCase(),
        role: "",
    };

    handleOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({
            open: false,
            email: "",
            username: "",
            pass: (Date.now().toString(36) + Math.random().toString(36).substr(2, 6)).toUpperCase(), //random temporary pass
            role: "",
        });
    };

    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        });
    };
    handleUsernameChange = event => {
        this.setState({
            username: event.target.value
        });
    };
    handlePassChange = event => {
        this.setState({
            pass: event.target.value
        });
    };
    handleRoleChange = event => {
        this.setState({
            role: event.target.value
        });
    };

    render() {
        const {classes, onSubmit} = this.props;
        return (
            <React.Fragment>
                <Button variant="extendedFab" color={"primary"} onClick={this.handleOpen}>
                    <AddIcon/>
                    Stwórz użytkownika
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Stwórz nowego użytkownika</DialogTitle>
                    <DialogContent>
                        <form className={classes.form}
                              onSubmit={(event) => {
                                  onSubmit(this.state.email, this.state.pass, this.state.username, this.state.role, event);
                                  this.handleClose();
                              }}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Adres email</InputLabel>
                                {/*<Field name="email" component={renderInputField} type="email"/>*/}
                                <Input id="email" name="email" autoComplete="email" autoFocus
                                       value={this.state.email} onChange={this.handleEmailChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="text">Nazwa użytkownika</InputLabel>
                                {/*<Field name="email" component={renderInputField} type="email"/>*/}
                                <Input id="name" name="name" autoComplete="name"
                                       value={this.state.username} onChange={this.handleUsernameChange}
                                />
                            </FormControl>
                            {/*<FormControl margin="normal" required fullWidth>*/}
                            {/*<InputLabel htmlFor="password">Hasło</InputLabel>*/}
                            {/*/!*<Field name="password" component={renderInputField} type="password"/>*!/*/}
                            {/*<Input*/}
                            {/*name="password"*/}
                            {/*type="password"*/}
                            {/*id="password"*/}
                            {/*autoComplete="current-password"*/}
                            {/*value={this.state.pass} onChange={this.handlePassChange}*/}
                            {/*/>*/}
                            {/*</FormControl>*/}

                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Typ użytkownika</FormLabel>
                                <RadioGroup
                                    aria-label="Typ"
                                    name="type"
                                    className={classes.group}
                                    value={this.state.role}
                                    onChange={this.handleRoleChange}
                                >
                                    <FormControlLabel value="user" control={<Radio/>}
                                                      label="Zwykły użytkownik (zarządzanie własnymi wybieraczkami)"/>
                                    <FormControlLabel value="admin" control={<Radio/>}
                                                      label="Administrator (zarządzanie wszystkimi wybieraczkami i użytkownikami)"/>
                                </RadioGroup>
                            </FormControl>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Utwórz
                            </Button>
                        </form>
                    </DialogContent>
                </Dialog>

            </React.Fragment>
        );
    }
}

CreateUserDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles)
)(CreateUserDialog);
