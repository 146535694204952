import React, {Component} from 'react';
import {Formik} from 'formik';
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Button from "@material-ui/core/Button/Button";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import {CirclePicker} from 'react-color';
import Typography from "@material-ui/core/Typography/Typography";
import ImageRenderer from "../../../ImageRenderer";
import {Divider} from "@material-ui/core";
import Delete from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import {red} from "@material-ui/core/colors";
import ImageSelectDialog from "./ImageSelectDialog";
import {firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import Select from "react-select";

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
    group: {
        display: 'block',
        margin: '50px auto',
        maxWidth: '400px',
    },
    colorPicker: {
        margin: "30px"
    },
    redButton: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
});

class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addConfigImageDialogOpen: false
        }
    }

    render() {
        const {classes, chooserData, onSubmit, onTouch, onChooserDeletion, onChooserDuplication, users} = this.props;
        let initialValues;
        if (typeof chooserData !== 'undefined') {
            initialValues = {
                name: chooserData.name,
                description: chooserData.description,
                startImageUrl: chooserData.startImageUrl,
                resultType: chooserData.resultType,
                buttonText: chooserData.buttonText,
                colorPrimary: chooserData.colorPrimary,
                colorSecondary: chooserData.colorSecondary,
                multiResultOption: chooserData.multiResultOption,
                creator: chooserData.creator,
            }
        } else {
            initialValues = {
                name: "",
                description: "",
                startImageUrl: "",
                resultType: "link",
                buttonText: "Zobacz stronę produktu!",
                colorPrimary: "#03a9f4",
                colorSecondary: "#cddc39",
                multiResultOption: "losuj",
                creator: "---",
            };
        }

        const usersSelectOptions = [];
        if (users)
            for (const userId in users) {
                usersSelectOptions.push({value: userId, label: users[userId].username});
            }

        return (
            <Paper className={classes.paper}>
                <Typography variant="h3" gutterBottom>Konfiguracja wybieraczki</Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        onSubmit(values, actions.resetForm);
                        actions.setSubmitting(false);
                    }}
                    render={({
                                 values,
                                 errors,
                                 status,
                                 touched,
                                 handleBlur,
                                 handleChange,
                                 handleSubmit,
                                 isSubmitting,
                                 dirty,
                                 setFieldValue
                             }) => {
                        onTouch(dirty);
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.buttons}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}>
                                            Zapisz
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Właściciel:</Typography>
                                        <div style={{width: "300px", margin: "auto", marginBottom: "30px"}}>
                                            <Select
                                                name="creator"
                                                value={values.creator}
                                                defaultValue={values.creator}
                                                onChange={(value) => {
                                                    setFieldValue("creator", value);
                                                }}
                                                options={usersSelectOptions}
                                                // styles={{container: () => ({width: "300px", margin: "auto"})}}
                                            />
                                        </div>

                                        <FormControl margin="normal" required>
                                            <InputLabel htmlFor="text">Nazwa</InputLabel>
                                            <Input
                                                // type="text"
                                                name="name"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                value={values.name}
                                            />
                                        </FormControl>
                                        <br/>
                                        <FormControl margin="normal">
                                            <InputLabel htmlFor="text">Opis</InputLabel>
                                            <Input
                                                name="description"
                                                onChange={handleChange}
                                                value={values.description}
                                                style={{width: "300px"}}
                                            />
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Button variant="extendedFab" color={"primary"}
                                                onClick={() => this.setState({addConfigImageDialogOpen: true})
                                                }>
                                            Załaduj obrazek początkowy
                                        </Button>
                                        <ImageSelectDialog isOpen={this.state.addConfigImageDialogOpen}
                                                           handleClose={() => this.setState({addConfigImageDialogOpen: false})}
                                                           imageUrl={values.startImageUrl}
                                                           handleSave={(newImageUrl) => {
                                                               setFieldValue("startImageUrl", newImageUrl);
                                                               this.setState({
                                                                   addConfigImageDialogOpen: false,
                                                               })
                                                           }}
                                        />
                                        <br/>
                                        <FormControl margin="normal">
                                            {/*<InputLabel htmlFor="text">Link do obrazka początkowego</InputLabel>*/}
                                            {/*<Input*/}
                                            {/*name="startImageUrl"*/}
                                            {/*onChange={handleChange}*/}
                                            {/*value={values.startImageUrl}*/}
                                            {/*style={{width: "300px"}}*/}
                                            {/*/>*/}
                                            {/*<br/>*/}
                                            <ImageRenderer url={values.startImageUrl} key={values.startImageUrl}/>
                                        </FormControl>
                                        <FormControl component="fieldset" className={classes.group}
                                        >
                                            <FormLabel component="legend">Po procesie wyboru pokaż:</FormLabel>
                                            <RadioGroup
                                                aria-label="Result type"
                                                name="resultType"
                                                value={values.resultType}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="dataForm" control={<Radio/>} label="Formularz zbierania danych"/>
                                                <FormControlLabel value="link" control={<Radio/>} label="Link"/>
                                                <FormControlLabel value="buybox" control={<Radio/>} label="BuyBox"/>
                                            </RadioGroup>

                                        </FormControl>
                                        <FormControl margin="normal" required>
                                            <InputLabel htmlFor="text">Jeśli to link, to co ma być napisane na przycisku?</InputLabel>
                                            <Input
                                                name="buttonText"
                                                onChange={handleChange}
                                                value={values.buttonText}
                                                style={{width: "500px"}}
                                            />
                                        </FormControl>
                                        <FormControl component="fieldset" className={classes.group}
                                        >
                                            <FormLabel component="legend">Gdy zostanie wybranych kilka
                                                możliwości:</FormLabel>
                                            <RadioGroup
                                                aria-label="MultiResultOption"
                                                name="multiResultOption"
                                                value={values.multiResultOption}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="losuj" control={<Radio/>} label="Wylosuj"/>
                                                <FormControlLabel value="priorytet" control={<Radio/>}
                                                                  label="Weź najwyższą w tabeli produktów"/>
                                            </RadioGroup>

                                        </FormControl>
                                        <FormControl margin="normal">
                                            <FormLabel component="legend">Kolor główny:</FormLabel>
                                            <div className={classes.colorPicker}>
                                                <CirclePicker color={values.colorPrimary}
                                                              onChangeComplete={(color) => {
                                                                  setFieldValue("colorPrimary", color.hex);
                                                              }}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormControl margin="normal">
                                            <FormLabel component="legend">Kolor dodatkowy:</FormLabel>
                                            <div className={classes.colorPicker}>
                                                <CirclePicker color={values.colorSecondary}
                                                              onChangeComplete={(color) => {
                                                                  setFieldValue("colorSecondary", color.hex);
                                                              }}
                                                />
                                            </div>

                                        </FormControl>

                                    </Grid>

                                </Grid>

                            </form>
                        )
                    }}
                />
                <Divider style={{margin: "50px"}}/>
                <Button variant="extendedFab" color={"primary"}
                        className={classes.button}
                        onClick={() => onChooserDuplication(chooserData)}>
                    <FileCopy/>
                    Duplikuj wybieraczkę
                </Button>
                <Button variant="extendedFab" color={"primary"}
                        onClick={e =>
                            window.confirm("Czy na pewno chcesz usunąć wybieraczkę? Tego nie można cofnąć!") &&
                            onChooserDeletion()
                        }
                        className={`${classes.button} ${classes.redButton}`}>
                    <Delete/>
                    Usuń wybieraczkę
                </Button>
            </Paper>
        );
    }
}

Configuration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect((props) => [
        {collection: 'users'},
    ]),
    // firestoreConnect(['wybieraczki']),
    connect((state, props) => ({
        users: state.firestore.data.users,
        // choosersData: state.firestore.data.wybieraczki,
        // singleChooserData: state.firestore.data[props.match.params.id] // it could be done better - connect just needed chooser. Should it?
    })),
    withStyles(styles)
)(Configuration)