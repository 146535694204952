import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {CSVLink} from "react-csv";
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: "20px",
    },
};


function StatisticsProductsTable(props) {
    const {classes, products, chooserName} = props;
    const sortedProducts = products.slice();
    sortedProducts.sort((a, b) => {
        if (typeof a.clicksCounter === 'undefined' && typeof b.clicksCounter === 'undefined')
            return 0;
        if (typeof a.clicksCounter === 'undefined')
            return 1;
        if (typeof b.clicksCounter === 'undefined')
            return -1;
        return (b.clicksCounter - a.clicksCounter)
    });

    const exportProducts = [];
    exportProducts.push(["Kod EAN", "Nazwa produktu", "Ilość kliknięć"]);
    sortedProducts.map(product => {
        exportProducts.push([product.ean ? product.ean : "", product.product_name ? product.product_name : "", product.clicksCounter ? product.clicksCounter : 0])
    });

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>EAN</TableCell>
                            <TableCell>Nazwa</TableCell>
                            <TableCell numeric>Ilość kliknięć</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedProducts.map(product => {
                            return (
                                <TableRow key={product.id}>
                                    <TableCell component="th" scope="row">
                                        {product.ean ? product.ean : ""}
                                    </TableCell>
                                    <TableCell>{product.product_name ? product.product_name : ""}</TableCell>
                                    <TableCell numeric>{product.clicksCounter ? product.clicksCounter : 0}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <CSVLink data={exportProducts} separator={";"}
                     filename={`wybierator_${chooserName}_statystykiProduktow.csv`}
            >
                <Button color="primary" variant="contained" className={classes.button}>
                    Pobierz CSV
                </Button>
            </CSVLink>
        </React.Fragment>
    );
}

StatisticsProductsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatisticsProductsTable);
