import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems} from './listItems';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import HomePage from "../Content/HomePage/HomePage";
import Choosers from "../Content/Choosers/Choosers";
import Users from "../Content/Users/Users";
import Statistics from "../Content/Statistics";
import {compose} from "redux";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import AccountCircle from '@material-ui/icons/AccountCircle';
import {firestoreConnect} from "react-redux-firebase";
import ChoosersEditor from "../Content/Choosers/ChoosersEditor";
import ChoosersAdd from "../Content/Choosers/ChoosersAdd";
import connect from "react-redux/es/connect/connect";
import {UserIsDeleted} from "../Login/AuthHOC";
import GalleryFolders from "../Content/Gallery/GalleryFolders";
import MobileApp from "../Content/MobileApp/MobileApp";

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
});

class Dashboard extends React.Component {
    state = {
        open: true,
        anchorEl: null,
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleChange = event => {
        this.setState({auth: event.target.checked});
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogout = () => {
        this.handleClose();
        this.props.firebase.logout();
    };

    render() {
        const {classes, auth, profile} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <CssBaseline/>
                <div className={classes.root}>
                    <AppBar
                        position="absolute"
                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden,
                                )}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="title" color="inherit" noWrap className={classes.title}>
                                Wybierator - panel administracyjny
                            </Typography>

                            <div>
                                <IconButton
                                    aria-owns={open ? 'menu-appbar' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    {/*<MenuItem onClick={this.handleClose}>Ustawienia konta</MenuItem>*/}
                                    <MenuItem onClick={this.handleLogout}>Wyloguj</MenuItem>
                                </Menu>
                            </div>

                            {/*<IconButton color="inherit">*/}
                            {/*<Badge badgeContent={4} color="secondary">*/}
                            {/*<NotificationsIcon />*/}
                            {/*</Badge>*/}
                            {/*</IconButton>*/}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider/>
                        <List>{mainListItems(profile.role)}</List>
                        {/*<Divider />*/}
                        {/*<List>{secondaryListItems}</List>*/}
                    </Drawer>
                    <main className={classes.content}>
                        <Switch>
                            <Route exact path="/start" component={HomePage}/>
                            <Route exact path="/wybieraczki" component={Choosers}/>
                            <Route exact path="/wybieraczki/dodaj" component={ChoosersAdd}/>
                            <Route path="/wybieraczki/:id" component={ChoosersEditor}/>
                            <Route path="/uzytkownicy" component={Users}/>
                            <Route path="/galeria" component={GalleryFolders}/>
                            <Route path="/statystyki" component={Statistics}/>
                            <Route path="/aplikacjaMobilna" component={MobileApp}/>
                            <Redirect from="/" to="/start"/>
                        </Switch>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(),
    withStyles(styles),
    withRouter,
    connect((state) => ({
        auth: state.firebase.auth, // pass auth data as this.props.auth
        profile: state.firebase.profile
    })),
    UserIsDeleted,
)(Dashboard);
