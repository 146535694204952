import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import {connect} from "react-redux";
import LoadingScreen from "../../Login/LoadingScreen";
import SimpleStatCard from "./SimpleStatCard";
import Grid from "@material-ui/core/Grid";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
});

class HomePage extends Component {

    // ----------------- STATISTICS ------------------
    allChoosersViews = (choosers) => {
        let counter = 0;
        choosers.map((chooser => {
            counter += chooser.viewsCounter;
        }));
        return counter;
    };

    mobileAppChoosers = (categories) => {
        console.log(categories);
        let counter = 0;

        for (const category in categories)
            counter += categories[category].choosers.length;

        return counter;
    };


    // ----------------- RENDER ------------------

    renderLoading = () => {
        return (
            <div>
                <div className={this.props.classes.appBarSpacer}/>
                <LoadingScreen/>
            </div>);
    };
    renderHomePage = () => {
        return (
            <div>
                <div className={this.props.classes.appBarSpacer}/>
                <Typography variant="display1" gutterBottom>
                    Ogólne statystyki wybieratora:
                </Typography>

                <div className={this.props.classes.appBarSpacer}/>

                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={24}
                >
                    <Grid item xs={3}>
                        <SimpleStatCard title={"Wszystkie wybieratory"} number={this.props.wybieraczki.length}/>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleStatCard title={"Łączna suma wyświetleń"}
                                        number={this.allChoosersViews(this.props.wybieraczki)}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleStatCard title={"Wybieratory mobilne"}
                                        number={this.mobileAppChoosers(this.props.mobileApp.categories)}/>
                    </Grid>

                </Grid>

            </div>);
    };

    render() {
        const {classes, users, wybieraczki, mobileApp} = this.props;

        if (isLoaded(users) && isLoaded(wybieraczki) && isLoaded(mobileApp)) {
            return (this.renderHomePage());
        } else {
            return (this.renderLoading());
        }
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki', 'users', 'mobileApp']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.ordered.wybieraczki,
        users: state.firestore.data.users,
        mobileApp: state.firestore.data.mobileApp,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles)
)(HomePage)
