import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {compose} from "redux";
import Input from "@material-ui/core/Input/Input";

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class LoginForm extends Component {
    state = {
        email: "",
        pass: ""
    };

    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        });
    };
    handlePassChange = event => {
        this.setState({
            pass: event.target.value
        });
    };

    render() {
        const {classes, onSubmit} = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockIcon/>
                        </Avatar>
                        <Typography component="h1" variant="headline">
                            Wybierator
                        </Typography>
                        <form className={classes.form}
                              onSubmit={(event) => onSubmit(this.state.email, this.state.pass, event)}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Adres email</InputLabel>
                                {/*<Field name="email" component={renderInputField} type="email"/>*/}
                                <Input id="email" name="email" autoComplete="email" autoFocus
                                       value={this.state.email} onChange={this.handleEmailChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Hasło</InputLabel>
                                {/*<Field name="password" component={renderInputField} type="password"/>*/}
                                <Input
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.pass} onChange={this.handlePassChange}
                                />

                            </FormControl>
                            {/*<FormControlLabel*/}
                            {/*control={<Checkbox value="remember" color="primary" />}*/}
                            {/*label="Remember me"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Zaloguj
                            </Button>
                        </form>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(
    withStyles(styles)
)(LoginForm);

