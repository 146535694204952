import React, {Component} from 'react';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import AnswersDialog from "./AnswersDialog";


let counter = 0;

function showAnswersDialog() {

}

function createData(name, organisation, email, phone, answers, chosenProductName) {
    counter += 1;
    const actionButtons = answers ? <AnswersDialog answers={answers} chosenProductName={chosenProductName || "---"}/>
    :
    "---"
    ;
    return [name, organisation, email, phone, actionButtons];
}

const columns = ["Imię i nazwisko", "Organizacja", "Email", "Telefon", "Udzielone odpowiedzi"];

const options = {
    filter: false,
    selectableRows: false,
    pagination: false,
    rowsPerPage: 1000,
    print: false,
    download: false,
    viewColumns: false,
};

const styles = theme => ({
    button: {
        margin: theme.spacing.unit * 2
    },
});

class DataTable extends Component {
    data = [];

    render() {
        const {classes, theme, className} = this.props;

        if (this.props.collectedData) {
            this.data = [];
            console.log(this.props.collectedData.data);
            this.props.collectedData.data.map((singleData) => {
                this.data.push(createData(singleData.name || "---", singleData.organisation || "---", singleData.email || "---", singleData.phone || "---", singleData.answers, singleData.chosenProductName));
            });
            console.log(this.data);
        }
        return (
            <div>
                <MUIDataTable
                    title={"Zebrane dane"}
                    data={this.data}
                    columns={columns}
                    options={options}
                />
            </div>
        );
    }
}

DataTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withRouter,
    firestoreConnect((props) => [{
        collection: 'collectedData',
        doc: props.match.params.id,
        storeAs: props.match.params.id + "_collectedData"
    }]),
    connect((state, props) => ({
        collectedData: state.firestore.data[props.match.params.id + "_collectedData"]
    })),
    withStyles(styles),
)(DataTable)