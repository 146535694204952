import React from 'react';
import Button from "@material-ui/core/Button/Button";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";

import AddIcon from '@material-ui/icons/Add';
import {arrayMove, SortableContainer} from "react-sortable-hoc";
import QuestionsSingleItem from "./QuestionsSingleItem"
import ImageSelectDialog from "./ImageSelectDialog";
//import Button from "@material-ui/core/Button/Button";

const styles = theme => ({
    root: {
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        //flexBasis: '33.33%',
        flexShrink: 0,
    },
    questionsList: {
        margin: "20px"
    }
});

const SortableList = SortableContainer(({items, questions, functionsPack, chooserData, expanded, imageDialogOpen}) => {
    // console.log(questions);
    return (
        <div style={{margin: "50px"}}>
            {questions.map((value, index) => (
                <QuestionsSingleItem functionsPack={functionsPack} questions={questions}
                                     key={`item-${index}`} index={index}
                                     value={value}
                                     chooserData={chooserData}
                                     expanded={expanded}
                                     imageDialogOpen={imageDialogOpen}/>
            ))}
        </div>
    );
});

class Questions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: null,
            labelWidth: 0,
            questions: typeof props.chooserData.questions === "undefined" ? [] : props.chooserData.questions,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
            imageDialogOpen: false,
            editedImageValueObject: null
        };
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleQuestionAdd = () => {
        let newQuestion = {
            id: (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase(),
            question: "",
            column: "",
        };
        let oldQuestions = this.state.questions;
        oldQuestions.push(newQuestion);
        this.setState({
            questions: oldQuestions
        });
        this.onQuestionsTouch();
        this.props.updateHeight();
    };

    handleQuestionDelete = (id) => {
        let oldQuestions = this.state.questions;
        let index = oldQuestions.findIndex((elem) => (elem.id === id));
        oldQuestions.splice(index, 1);
        this.setState({
            questions: oldQuestions
        });
        this.onQuestionsTouch();
        this.props.updateHeight();
    };

    handleNameChange = (event, value) => {
        let newQuestions = this.state.questions;
        let index = newQuestions.findIndex((elem) => (elem.id === value.id));
        newQuestions[index].question = event.target.value;

        this.setState({questions: newQuestions});
        this.onQuestionsTouch();
    };

    handleUrlChange = (url, value) => {
        let newQuestions = this.state.questions;
        let index = newQuestions.findIndex((elem) => (elem.id === value.id));
        newQuestions[index].imageUrl = url;

        this.setState({questions: newQuestions});
        this.onQuestionsTouch();
    };

    handleColumnChange = (event, value) => {
        let newQuestions = this.state.questions;
        let index = newQuestions.findIndex((elem) => (elem.id === value.id));
        newQuestions[index].column = event.target.value;

        this.setState({questions: newQuestions});
        this.onQuestionsTouch();
    };


    handleImageDialogOpen = (isOpen, editedQuestionValueObject) => {
        this.setState({
            imageDialogOpen: isOpen,
            editedImageValueObject: editedQuestionValueObject
        });
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        const {questions} = this.state;

        this.setState({
            questions: arrayMove(questions, oldIndex, newIndex),
        });
        this.onQuestionsTouch();
    };

    onQuestionsTouch() {
        this.props.onTouch(true);
    }

    componentDidMount() {
        this.setState({
            labelWidth: 63//ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }

    render() {
        const {classes, chooserData, onSubmit} = this.props;
        const {expanded, items} = this.state;

        const functionsPack = {
            handleChange: this.handleChange,
            handleQuestionAdd: this.handleQuestionAdd,
            handleQuestionDelete: this.handleQuestionDelete,
            handleNameChange: this.handleNameChange,
            handleUrlChange: this.handleUrlChange,
            handleColumnChange: this.handleColumnChange,
            handleImageDialogOpen: this.handleImageDialogOpen,
        };

        return (
            <div className={classes.root}>
                <ImageSelectDialog isOpen={this.state.imageDialogOpen}
                                   handleClose={() => this.handleImageDialogOpen(false, null)}
                                   imageUrl={this.state.editedImageValueObject ? this.state.editedImageValueObject.imageUrl : ""}
                                   handleSave={(newImageUrl) => {
                                       this.handleUrlChange(newImageUrl, this.state.editedImageValueObject);
                                       functionsPack.handleImageDialogOpen(false, null);
                                   }}
                />
                <Grid item>
                    {/*<CreateChooserDialog/>*/}

                    <Button variant="extendedFab" color={"primary"} onClick={this.handleQuestionAdd}
                            className={classes.button}>
                        <AddIcon/>
                        Dodaj
                    </Button>
                    <Button variant="extendedFab" color={"secondary"} onClick={() => {
                        onSubmit(this.state.questions)
                    }} className={classes.button}>
                        {/*<AddIcon/>*/}
                        Zapisz
                    </Button>

                </Grid>

                <SortableList items={items} onSortEnd={this.onSortEnd} useDragHandle={true}
                              expanded={this.state.expanded}
                              chooserData={chooserData}
                              questions={this.state.questions}
                              props={this.props}
                              functionsPack={functionsPack}
                              imageDialogOpen={this.state.imageDialogOpen}/>
            </div>
        );
    }
}

Questions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles)
)(Questions)