import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import LoadingScreen from './LoadingScreen'; // change it to your custom component
import {createBrowserHistory} from 'history';
import React from "react";


const browserHistory = createBrowserHistory();
const locationHelper = locationHelperBuilder({});


export const UserIsAuthenticated = connectedRouterRedirect({
    wrapperDisplayName: 'UserIsAuthenticated',
    AuthenticatingComponent: LoadingScreen,
    allowRedirectBack: true,
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || '/login',
    authenticatingSelector: ({firebase: {auth, profile, isInitializing}}) =>
        !auth.isLoaded || isInitializing === true,
    authenticatedSelector: ({firebase: {auth}}) =>
        auth.isLoaded && !auth.isEmpty,
    redirectAction: newLoc => (dispatch) => {
        browserHistory.replace(newLoc); // or routerActions.replace
        dispatch({type: 'UNAUTHED_REDIRECT'});
    },

});

export const UserIsNotAuthenticated = connectedRouterRedirect({
    wrapperDisplayName: 'UserIsNotAuthenticated',
    AuthenticatingComponent: LoadingScreen,
    allowRedirectBack: false,
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || '/',
    authenticatingSelector: ({firebase: {auth, isInitializing}}) =>
        !auth.isLoaded || isInitializing === true,
    authenticatedSelector: ({firebase: {auth}}) =>
        auth.isLoaded && auth.isEmpty,
    redirectAction: newLoc => (dispatch) => {
        browserHistory.replace(newLoc); // or routerActions.replace
        dispatch({type: 'UNAUTHED_REDIRECT'});
    },
});


export const UserIsNotAdmin = connectedRouterRedirect({
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false,
    authenticatedSelector: ({firebase: {auth, profile}}) =>
        auth.isLoaded && !auth.isEmpty && profile.role === 'admin',
    authenticatingSelector: ({firebase: {profile, auth, isInitializing}}) =>
        !auth.isLoaded || profile !== undefined || isInitializing === true,
    AuthenticatingComponent: LoadingScreen,
    redirectAction: newLoc => (dispatch) => {
        browserHistory.replace(newLoc); // or routerActions.replace
        dispatch({type: 'UNAUTHED_REDIRECT'});
    },
    wrapperDisplayName: 'UserIsNotAdmin'
});

export const UserIsDeleted = connectedRouterRedirect({
    redirectPath: '/userDeleted',
    allowRedirectBack: false,
    authenticatedSelector: ({firebase: {auth, profile}}) =>
        auth.isLoaded && !auth.isEmpty && !profile.deleted,
    authenticatingSelector: ({firebase: {profile, auth, isInitializing}}) =>
        auth === undefined || profile === undefined || isInitializing === true,
    AuthenticatingComponent: LoadingScreen,
    redirectAction: newLoc => (dispatch) => {
        browserHistory.replace(newLoc); // or routerActions.replace
        dispatch({type: 'UNAUTHED_REDIRECT'});
    },
    wrapperDisplayName: 'UserIsDeleted'
});

export const UserIsNotDeleted = connectedRouterRedirect({
    redirectPath: '/',
    allowRedirectBack: false,
    authenticatedSelector: ({firebase: {auth, profile}}) =>
        auth.isLoaded && !auth.isEmpty && profile.deleted,
    authenticatingSelector: ({firebase: {profile, auth, isInitializing}}) =>
        auth === undefined || profile === undefined || isInitializing === true,
    AuthenticatingComponent: LoadingScreen,
    redirectAction: newLoc => (dispatch) => {
        browserHistory.replace(newLoc); // or routerActions.replace
        dispatch({type: 'UNAUTHED_REDIRECT'});
    },
    wrapperDisplayName: 'UserIsDeleted'
});