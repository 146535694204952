import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import SimpleLineChart from "../Dashboard/SimpleLineChart";
import SimpleTable from "../Dashboard/SimpleTable";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
});

class Statistics extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div>
            </div>
        );
    }
}

Statistics.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Statistics);
