import React, {Component} from 'react';
import './App.css';
import Dashboard from "./Dashboard/Dashboard";
import {connect} from "react-redux";
import {compose} from "redux";
import {UserIsAuthenticated, UserIsNotAuthenticated} from "./Login/AuthHOC";
import {Route, Switch, withRouter} from "react-router-dom";
import SignIn from "./Login/Login";
import UserDeletedInfo from "./Login/UserDeletedInfo";

class App extends Component {

    render() {
        console.log(this.props.auth);
        return (
            <div className="App">
                <Switch>
                    <Route path="/login" component={UserIsNotAuthenticated(SignIn)}/>
                    <Route path="/userDeleted" component={UserIsAuthenticated(UserDeletedInfo)}/>
                    <Route component={UserIsAuthenticated(Dashboard)}/>
                </Switch>
                {/*<Dashboard/>*/}
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect((state) => ({
        // wybieraczki: state.firestore.ordered.wybieraczki
        profile: state.firebase.profile, // pass profile data as this.props.profile
        auth: state.firebase.auth // pass auth data as this.props.auth
    }))
)(App)