import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import {compose} from "redux";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import SwipeableViews from 'react-swipeable-views';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";

import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import Post from "./ProductsGetFromFirebase";




const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        margin: -theme.spacing.unit * 3,
    },
});


class ProductsNewRowEanDialog extends React.Component {
    state = {
        open: false,
        ean: '',
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleEanChange = (event) => {
        this.setState({ean: event.target.value});
    };

    handleData = (data) => {
        this.setState({eanProduct: data});
    };

    handleCreate = () => {
        console.log("!!!!!!!!!!!!!!!!!");
        let valuesFromEan;
        for (let property in this.state.eanProduct) {
            valuesFromEan = this.state.eanProduct[property];
        }
        console.log(valuesFromEan);
        this.props.handleRowAdd(valuesFromEan);
        this.handleClose()
    };

    render() {
        const {classes, theme, className} = this.props;
        return (
            <React.Fragment>
                <Button className={className} variant="extendedFab" color={"primary"} onClick={this.handleClickOpen}>
                    <AddIcon/>
                    Dodaj produkt z ean
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Dodaj produkt z bazy ean.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Tymczasowo: Wyszukaj numer EAN na komputronik.pl (???)
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="ean"
                            label="Numer EAN"
                            type="text"
                            variant="outlined"
                            onChange={this.handleEanChange}
                            fullWidth
                        />

                        <Post ean={this.state.ean} onProductFound={this.handleData} />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={this.handleCreate} color="primary">
                            Utwórz
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ProductsNewRowEanDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, {withTheme: true})
)(ProductsNewRowEanDialog)