import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import firebase from "firebase";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import Gallery from "react-grid-gallery";
import {firebaseConnect, firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
// import Select from "../../Gallery/GalleryFolders";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        margin: -theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
});

class ImageSelectDialog extends Component {
    state = {
        username: "",
        avatar: "",
        isUploading: false,
        progress: 0,
        imageUrl: this.props.imageUrl,
        initialImageUrl: this.props.imageUrl,
        tab: 0,
        imagesURLs: [],
        galleryImages: [],
        category: ""
    };

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (props.imageUrl !== state.initialImageUrl) {
            newState.imageUrl = props.imageUrl;
            newState.initialImageUrl = props.imageUrl;
        }
        if (props.images)
            if (state.imagesURLs !== props.images) {
                const galleryImages = [];
                props.images.map(obj => {
                    galleryImages.push(
                        {
                            src: obj.value.path,
                            thumbnail: obj.value.thumbnail,
                            thumbnailWidth: 200,
                            thumbnailHeight: 200,
                            category: obj.value.category,
                        }
                    )
                });
                newState.imagesURLs = props.images.imagesURLs;
                newState.galleryImages = galleryImages;
            }
        return newState;
    }

    handleChange = (event) => {
        this.setState({category: event.target.value})
    };

    handleTabChange = (event, tab) => {
        this.setState({tab});
    };

    handleUploadSuccess = filename => {
        this.setState({avatar: filename, progress: 100, isUploading: false});
        firebase
            .storage()
            .ref("singleUseImages")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                this.props.handleSave(url);
                this.handleTabChange(undefined, 0);
            });
    };
    handleProgress = progress => this.setState({progress});

    onClickThumbnail = (index) => {
        const categoryFilteredGallery = this.state.galleryImages.filter(elem => elem.category === this.state.category);
        this.props.handleSave(categoryFilteredGallery[index].src);
    };

    handleLinkChange = (e) => {
        this.setState({imageUrl: e.target.value})
    };

    render() {
        const {isOpen, theme, imagesCategoriesObj, classes} = this.props;

        if (!imagesCategoriesObj) {
            return (<div></div>);
        } else {
            const imagesCategories = imagesCategoriesObj.categories.categoriesArray;
            const categoryFilteredGallery = this.state.galleryImages.filter(elem => elem.category === this.state.category);

            // console.log(this.props.imageUrl);
            return (
                <Dialog
                    open={isOpen}
                    value={"aaa"}
                    // onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Załaduj obrazek</DialogTitle>
                    <DialogContent>

                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            fullWidth
                        >
                            <Tab label="Obrazek"/>
                            <Tab label="Załaduj nowy"/>
                            <Tab label="Wybierz z bazy"/>
                        </Tabs>

                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={this.state.tab}
                            onChangeIndex={(index) => this.handleTabChange(undefined, index)}
                        >
                            <React.Fragment>
                                <FormControl margin="normal">
                                    <InputLabel htmlFor="text">Link</InputLabel>
                                    <Input
                                        name="startImageUrl"
                                        onChange={this.handleLinkChange}
                                        value={this.state.imageUrl}
                                        style={{width: "500px"}}
                                    />
                                    <br/>
                                    {this.state.imageUrl &&
                                    <img alt="Loaded image" src={this.state.imageUrl} style={{width: "500px"}}/>}
                                    <br/>
                                </FormControl>
                            </React.Fragment>
                            <React.Fragment>
                                <br/>
                                Obrazki jeszcze nie są zmniejszane - pamiętaj, żeby były małe, aby nie spowolnić
                                ładowania
                                stron.
                                <br/>
                                <br/>
                                Załadowany tutaj obrazek NIE będzie dodany do bazy obrazów.
                                <br/>
                                <br/>
                                <div style={{textAlign: "center"}}>
                                    <CustomUploadButton
                                        accept="image/*"
                                        name="image"
                                        // maxWidth="5000"
                                        // maxHeight="5000"
                                        storageRef={firebase.storage().ref('singleUseImages')}
                                        onUploadStart={this.handleUploadStart}
                                        onUploadError={this.handleUploadError}
                                        onUploadSuccess={this.handleUploadSuccess}
                                        onProgress={this.handleProgress}
                                        style={{
                                            backgroundColor: 'steelblue',
                                            color: 'white',
                                            padding: 10,
                                            borderRadius: 4
                                        }}
                                        multiple
                                        randomizeFilename
                                    >
                                        Załaduj nowe zdjęcie
                                    </CustomUploadButton>
                                </div>
                                <br/>
                                <br/>
                                <LinearProgress variant="determinate" value={this.state.progress}/>
                            </React.Fragment>
                            <React.Fragment>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="category">Kategoria</InputLabel>
                                    <Select
                                        value={this.state.category}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'category',
                                            id: 'category',
                                        }}
                                    >
                                        {imagesCategories.map(category => (
                                            <MenuItem value={category} key={category}>{category}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                <Gallery images={categoryFilteredGallery}
                                         enableImageSelection={false}
                                         onClickThumbnail={this.onClickThumbnail}
                                />
                            </React.Fragment>
                        </SwipeableViews>


                        <br/>
                        {this.state.isUploading && <p>Progress: {this.state.progress}</p>}


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={() => this.props.handleSave(this.state.imageUrl)} color="primary">
                            Zapisz
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }
    }
}

ImageSelectDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firebaseConnect([
        'images'
    ]),
    firestoreConnect(['images/categories']), // or { collection: 'todos' }
    connect((state) => ({
        images: state.firebase.ordered.images,
        imagesCategoriesObj: state.firestore.data.images,
    })),
    withStyles(styles, {withTheme: true})
)(ImageSelectDialog)