import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import SwipeableViews from 'react-swipeable-views';
import Configuration from "./EditorTabs/Configuration";
import {connect} from "react-redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import LoadingScreen from "../../Login/LoadingScreen";
import Products from "./EditorTabs/Products";
import {deleteColumnsEditors} from "./EditorTabs/tools";
import Questions from "./EditorTabs/Questions";
import {withSnackbar} from "notistack";
import {Prompt, withRouter} from "react-router-dom";
import Statistics from "./EditorTabs/Statistics";
import Export from "./EditorTabs/Export/Export";
import DataCollection from "./EditorTabs/DataCollection/DataCollection";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    tabsBar: {
        flexGrow: 1,
        margin: -theme.spacing.unit * 3,
        // position: "fixed",
        // width: "100%",
        // zIndex: 1,
        // left: 0,
        // right: 0,
    },
    dirty: {
        backgroundColor: "pink",
    },
    tabContent: {
        padding: "10px"
    }
});

class ChoosersEditor extends Component {
    state = {
        tabIndex: 0,
        isConfigurationDirty: false,
        isProductsDirty: false,
        isQuestionsDirty: false,
        swipeableActions: {updateHeight: null}
    };

    handleChange = (event, value) => {
        this.setState({tabIndex: value});
    };
    handleChangeIndex = index => {
        this.setState({tabIndex: index});
        // DZIWNY PROBLEM PRZEŁĄCZENIA NA DRUGI EKRAN PRZY ŁADOWANIU...
    };
    handleChooserDeletion = () => {
        console.log("Zaraz usunę! :O");

        const docRef = this.props.firestore.collection('wybieraczki').doc(this.props.match.params.id);
        const newDocRef = this.props.firestore.collection("wybieraczkiDeleted").doc();

        this.props.firestore.runTransaction(t => {
            return t.get(docRef)
                .then(doc => {
                    t.set(newDocRef, doc.data());
                    t.delete(docRef);
                });
        }).then(() => {
            this.props.enqueueSnackbar("Wybieraczka usunięta!", {
                variant: 'success',
            });
            this.props.history.replace('/wybieraczki');
        })
            .catch((error) => {
                this.props.enqueueSnackbar("Wystąpił błąd - usuwanie się nie powiodło... :( " + error, {
                    variant: 'error',
                })
            });
    };

    handleChooserDuplication = (chooser) => {
        const chooserDeepCopy = JSON.parse(JSON.stringify(chooser)); // deep copy fix strange problem with firebase
        chooserDeepCopy.name += "_KOPIA";
        chooserDeepCopy.viewsCounter = 0;
        chooserDeepCopy.creationDate = new Date().toISOString().slice(0, 10);
        delete chooserDeepCopy.id;
        chooserDeepCopy.productsRows.map(prod => {
            delete prod.clicksCounter
        });
        // console.log(chooser);
        // console.log(chooserDeepCopy);

        const newDocRef = this.props.firestore.collection("wybieraczki").doc();

        this.props.firestore.runTransaction(t => {
            return t.get(newDocRef)
                .then(doc => {
                    t.set(newDocRef, chooserDeepCopy);
                });
        })
            .then(result => {
                this.props.enqueueSnackbar("Zduplikowano!", {
                    variant: 'success',
                });
                this.props.history.push(`/wybieraczki/${newDocRef.id}`);
            }).catch(err => {
            console.log('Transaction failure:', err);
            this.props.enqueueSnackbar("Coś poszło nie tak... :(", {
                variant: 'error',
            })
        });
    };

    handleConfigurationUpdate = (values, resetForm) => {
        this.props.firestore.update({collection: 'wybieraczki', doc: this.props.match.params.id}, values)
            .then(() => {
                this.props.enqueueSnackbar("Konfiguracja wybieraczki zapisana!", {
                    variant: 'success',
                });
                resetForm();
                this.handleConfigurationDirt(false);
            })
            .catch(() => {
                this.props.enqueueSnackbar("Wystąpił błąd - zmiany nie zostały zapisane... :(", {
                    variant: 'error',
                })
            });
    };
    handleProductsUpdate = (columns, rows, options) => {
        const newColumns = deleteColumnsEditors(columns, options);
        this.props.firestore.update({
            collection: 'wybieraczki',
            doc: this.props.match.params.id
        }, {productsColumns: newColumns, productsRows: rows})
            .then(() => {
                this.props.enqueueSnackbar("Lista produktów zapisana!", {
                    variant: 'success',
                });
                this.handleProductsDirt(false);
            })
            .catch(() => {
                this.props.enqueueSnackbar("Wystąpił błąd - zmiany nie zostały zapisane... :(", {
                    variant: 'error',
                })
            })
    };
    handleQuestionsUpdate = (questions) => {
        this.props.firestore.update({
            collection: 'wybieraczki',
            doc: this.props.match.params.id
        }, {
            questions: questions
        })
            .then(() => {
                this.props.enqueueSnackbar("Lista pytań zapisana!", {
                    variant: 'success',
                });
                this.handleQuestionsDirt(false);
            })
            .catch(() => {
                this.props.enqueueSnackbar("Wystąpił błąd - zmiany nie zostały zapisane... :(", {
                    variant: 'error',
                })
            });
    };

    handleDataCollectionUpdate = (dataTypes) => {
        this.props.firestore.update({
            collection: 'wybieraczki',
            doc: this.props.match.params.id
        }, {
            dataCollection: dataTypes
        })
            .then(() => {
                this.props.enqueueSnackbar("Zapisano konfigurację zbierania danych!", {
                    variant: 'success',
                });
                this.handleDataCollectionDirt(false);
            })
            .catch(() => {
                this.props.enqueueSnackbar("Wystąpił błąd - zmiany nie zostały zapisane... :(", {
                    variant: 'error',
                })
            });
    };

    componentDidUpdate = () => {
        if ((this.state.isConfigurationDirty || this.state.isQuestionsDirty || this.state.isProductsDirty)) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    };
    renderConfigForm = (chooser) => {
        const {classes, theme} = this.props;

        return (
            <div>
                <Prompt
                    when={(this.state.isConfigurationDirty || this.state.isQuestionsDirty || this.state.isProductsDirty)}
                    message='Nie zapisano zmian - na pewno chcesz wyjść?'
                />
                <div className={classes.appBarSpacer}/>
                <Paper className={classes.tabsBar}>
                    <Tabs
                        value={this.state.tabIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label={`Konfiguracja ${this.state.isConfigurationDirty ? "*" : ""}`}
                             className={this.state.isConfigurationDirty ? classes.dirty : ""}/>
                        <Tab label={`Produkty ${this.state.isProductsDirty ? "*" : ""}`}
                             className={this.state.isProductsDirty ? classes.dirty : ""}/>
                        <Tab label={`Pytania ${this.state.isQuestionsDirty ? "*" : ""}`}
                             className={this.state.isQuestionsDirty ? classes.dirty : ""}/>
                        <Tab label="Statystyki"/>
                        <Tab label="Eksport"/>
                        {chooser.resultType === "dataForm" &&
                        <Tab label={`Zbieranie danych ${this.state.isQuestionsDirty ? "*" : ""}`}
                             className={this.state.isQuestionsDirty ? classes.dirty : ""}/>
                        }
                    </Tabs>
                </Paper>
                <div className={classes.appBarSpacer}/>
                <SwipeableViews
                    axis='x'
                    index={this.state.tabIndex}
                    disabled={true}
                    animateHeight
                    action={actions => {
                        this.setState({swipeableActions: actions});
                    }}
                    // onChangeIndex={(index) => this.handleChangeIndex(index)}         // DZIWNY PROBLEM PRZEŁĄCZENIA NA DRUGI EKRAN PRZY ŁADOWANIU...
                >
                    <div className={classes.tabContent}>
                        <Configuration chooserData={chooser} onSubmit={this.handleConfigurationUpdate}
                                       onTouch={(isDirty) => this.handleConfigurationDirt(isDirty)}
                                       onChooserDeletion={this.handleChooserDeletion}
                                       onChooserDuplication={this.handleChooserDuplication}/>
                    </div>
                    <div className={classes.tabContent}>
                        <Products chooserData={chooser} onSubmit={this.handleProductsUpdate}
                                  onTouch={(isDirty) => this.handleProductsDirt(isDirty)}/>
                    </div>
                    <div className={classes.tabContent} style={{
                        height: "80vh", overflowY: 'auto',
                    }}>
                        <Questions chooserData={chooser} onSubmit={this.handleQuestionsUpdate}
                                   onTouch={(isDirty) => this.handleQuestionsDirt(isDirty)}
                                   updateHeight={() => this.state.swipeableActions.updateHeight()}/>
                    </div>
                    <div className={classes.tabContent}>
                        <Statistics chooserData={chooser} chooserStatistics={this.props.chooserStatistics}
                                    users={this.props.users}/>
                    </div>
                    <div className={classes.tabContent}>
                        <Export chooserData={chooser}
                                updateHeight={() => this.state.swipeableActions.updateHeight()}/>
                    </div>
                    {chooser.resultType === "dataForm" &&
                    <div className={classes.tabContent}>
                        <DataCollection chooserData={chooser} onSubmit={this.handleDataCollectionUpdate}
                                        onTouch={(isDirty) => this.handleDataCollectionDirt(isDirty)}
                                        updateHeight={() => this.state.swipeableActions.updateHeight()}/>
                    </div>}
                </SwipeableViews>
            </div>
        );
    };
    renderLoading = (classes) => {
        return (<div>
            <div className={classes.appBarSpacer}/>
            <LoadingScreen/>
        </div>);
    };

    handleConfigurationDirt(isDirty) {
        this.setState((prevState) => {
            if (prevState.isConfigurationDirty !== isDirty)
                return {isConfigurationDirty: isDirty}
        })
    };

    handleProductsDirt(isDirty) {
        this.setState({isProductsDirty: isDirty});
    };

    handleQuestionsDirt(isDirty) {
        this.setState({isQuestionsDirty: isDirty})
    };

    handleDataCollectionDirt(isDirty) {
        this.setState({isDataCollectionDirty: isDirty})
    };

    componentDidMount() {
        this.setState({tabIndex: 0});
    }

    render() {
        const {classes, theme, singleChooserData, chooserStatistics, users, wybieraczki, match} = this.props;

        if (isLoaded(users) && isLoaded(chooserStatistics) && isLoaded(wybieraczki)) {
            const wyb = this.props.wybieraczki.find((elem) => (this.props.match.params.id === elem.id));
            // console.log(this.props.wybieraczki);
            return (this.renderConfigForm(wyb));
        } else {
            return (this.renderLoading(classes));
        }
    }
}

ChoosersEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
};


export default compose(
    firestoreConnect((props) => [
        // {collection: 'wybieraczki', doc: props.match.params.id, storeAs: props.match.params.id}, // or `todos/${props.todoId}`
        'wybieraczki',
        {collection: 'statistics', doc: props.match.params.id, storeAs: props.match.params.id + "_stats"},
        {collection: 'users'},
    ]),
    // firestoreConnect(['wybieraczki', 'users']),
    connect((state, props) => ({
        wybieraczki: state.firestore.ordered.wybieraczki,
        chooserStatistics: state.firestore.ordered[props.match.params.id + "_stats"],
        // singleChooserData: state.firestore.data[props.match.params.id], // it could be done better - connect just needed chooser. Should it?
        users: state.firestore.data.users, // pass profile data as this.props.profile
    })),
    withStyles(styles),
    withSnackbar,
    withRouter
)(ChoosersEditor)