import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AnswersDialogContent from "./AnswersDialogContent";
import {compose} from "redux";

class AnswersDialog extends Component {
    state = {
        open: false,
    };

    handleOpen = () => this.setState({open: true});
    handleClose = () => this.setState({open: false});

    render() {
        const {classes, iframeId} = this.props;

        if (this.props.answers)
            return (
                <React.Fragment>
                    <Button onClick={this.handleOpen} variant="contained">Pokaż</Button>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth={false}
                    >
                        <AnswersDialogContent answers={this.props.answers} chosenProductName={this.props.chosenProductName}/>                    </Dialog>
                </React.Fragment>
            );
        else
            return (<>"---"</>);
    }
}

export default compose(
    // withStyles(styles, {withTheme: true}),
)(AnswersDialog)