import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";
import ExportAddDialog from "./ExportAddDialog";
import TextField from "@material-ui/core/TextField/TextField";
import IframeDialog from "./IframeDialog";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    button: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    textField: {
        width: "100%"
    }
});

class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportUsersList: [],
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.exportUsersList !== props.chooserData.exportUsers) {
            return {
                exportUsersList: props.chooserData.exportUsers ? props.chooserData.exportUsers : [],
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.updateHeight();
    }

    render() {
        const {classes, wybieraczki, chooserData} = this.props;
        return (
            <div>
                <ExportAddDialog/>

                {this.state.exportUsersList.map((exportUser) => {
                    console.log(exportUser);
                    return (
                        <Paper className={classes.paper} key={exportUser.value}>
                            <Typography variant="h5">Użytkownik: {exportUser.label || "Nie ustawiony"}</Typography>
                            <TextField
                                id="outlined-read-only-input"
                                label="Link"
                                defaultValue={"http://app.wybierator.pl/" + this.props.match.params.id + "/" + exportUser.value}
                                className={classes.textField}
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                            {exportUser.iframe &&
                            <TextField
                                id="outlined-read-only-input"
                                label="IFrame"
                                defaultValue={
                                    `<iframe
    width="700" height="400" style="border: 0;"
    src="http://iframe.wybierator.pl/${exportUser.iframe}">
 </iframe>`
                                }
                                className={classes.textField}
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                multiline
                            />
                            }

                            <IframeDialog iframeId={exportUser.iframe} chooserId={this.props.match.params.id}
                                          exportUser={exportUser}/>

                        </Paper>
                    )
                })}

            </div>
        );
    }
}

Export.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki', 'users']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.data.wybieraczki,
        users: state.firestore.data.users,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(Export)