import React from "react";
import Edit from '@material-ui/icons/Edit';


import {Editors, Formatters} from 'react-data-grid-addons';

const {AutoComplete: AutoCompleteEditor} = Editors;
const {ImageFormatter} = Formatters;

export const CustomColumnHeader = (props) => ({
    render() {
        // console.log(props.openColumnEditDialog);

        return (
            <React.Fragment>
                {this.props.column.name}
                <br/>
                <Edit onClick={() => {
                    props.openColumnEditDialog(this.props.column.key)
                }}
                      style={{width: "18px", color: "#4a9de2"}}/>
            </React.Fragment>
        )
    }
});

export function createColumnsEditors(columns, openColumnEditDialog) {
    return columns.map((item, key) => {
        if (item.columnType === 1) {
            item.editor = <AutoCompleteEditor options={item.options}/>;
        }
        if (item.key === "merchant_image_url") {
            item.formatter = ImageFormatter;
        }
        item.headerRenderer = <CustomColumnHeader openColumnEditDialog={openColumnEditDialog}/>;
        // item.width = 200;
        return item;
    });
}

export function deleteColumnsEditors(columns) {
    const newColumns = JSON.parse(JSON.stringify(columns));
    return newColumns.map((item, key) => {
        // console.log(item);
        if (typeof item.editor !== 'undefined') {
            delete item.editor;
        }
        if (typeof item.formatter !== 'undefined') {
            delete item.formatter;
        }
        delete item.headerRenderer;
        // console.log(item);
        return item;
    });
}