import MUIDataTable from "mui-datatables";

import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import {firestoreConnect} from "react-redux-firebase";

let counter = 0;

const styles = theme => ({
    button: {
        margin: theme.spacing.unit * 2
    },
});

function createData(id, name, user, creation_date, questionNumber, viewsCounter, classes) {
    counter += 1;
    const actionButtons = <div>
        <Link to={"/wybieraczki/" + id}>
            <Button variant="contained" target="_blank" className={classes.button}>Edytuj</Button>
        </Link>
        {/*<Button href={"http://wybieraczka.surge.sh/" + id} variant="contained" target="_blank"*/}
        {/*className={classes.button}>Link</Button>*/}
    </div>;
    return [name, user, creation_date, questionNumber, viewsCounter, actionButtons];
}

const columns = ["Wybieraczka", "Użytkownik", "Data utworzenia", "Ilość pytań", "Wyświetlenia", "Akcje"];

const options = {
    filterType: 'checkbox',
    selectableRows: false
};


class ChoosersDataTable extends Component {
    data = [];

    render() {
        const {classes, theme, className} = this.props;
        if (this.props.wybieraczki && this.props.users && this.props.auth) {
            // console.log(this.props.profile);
            this.data = [];
            this.props.wybieraczki.map((wybieraczka, index) => {
                {
                    if (wybieraczka.name === "Zmywarki")
                        console.log(wybieraczka.productsRows);

                    let chooserCreator = "---";
                    let chooserCreatorId = "";
                    if (wybieraczka.creator) {
                        chooserCreator = wybieraczka.creator.label ? wybieraczka.creator.label : "-";
                        chooserCreatorId = wybieraczka.creator.value ? wybieraczka.creator.value : "-";
                    }
                    if (!wybieraczka.deleted)
                        if (this.props.profile.role === 'admin' || this.props.auth.uid === chooserCreatorId)
                            this.data.push(createData(wybieraczka.id, wybieraczka.name, chooserCreator, wybieraczka.creationDate || "---", wybieraczka.questions.length, wybieraczka.viewsCounter, classes));
                }
            });

        }
        return (
            <div>
                <MUIDataTable
                    title={"Lista wybieraczek"}
                    data={this.data}
                    columns={columns}
                    options={options}
                />
            </div>
        );
    }
}

ChoosersDataTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki', 'users']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.ordered.wybieraczki,
        users: state.firestore.data.users,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles)
)(ChoosersDataTable)