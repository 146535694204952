import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingScreen from "../../Login/LoadingScreen";
import NewFolderDialog from "./NewFolderDialog";
import Grid from "@material-ui/core/Grid";
import GalleryPage from "./GalleryPage";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
});

class GalleryFolders extends Component {
    state = {
        category: ""
    };

    handleChange = (event) => {
        this.setState({category: event.target.value})
    };

    handleCategoryDelete = () => {
        const ref = this.props.firestore.collection('images').doc('categories');

        ref.update({
            categoriesArray: this.props.firestore.FieldValue.arrayRemove(this.state.category)
        });

        this.setState({category: ""});
    };

    render() {
        const {classes, images} = this.props;


        if (!isLoaded(images))
            return (<div>
                <div className={classes.appBarSpacer}/>
                <LoadingScreen/>
            </div>);
        else {
            const imagesCategories = images.categories.categoriesArray;

            const deleteTooltip = `
            Usuniętej kategorii nie będzie widać, 
            ale zdjęcia nie znikną z wybieraczek, w których są już używane. 
            Stworzenie ponownie kategorii o identycznej nazwie pozwala na cofnięcie tej oparacji.
        `;

            return (
                <div>
                    <div className={classes.appBarSpacer}/>

                    <Grid container spacing={24} justify="center" alignItems="center">
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="category">Kategoria</InputLabel>
                                <Select
                                    value={this.state.category}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'category',
                                        id: 'category',
                                    }}
                                >
                                    {imagesCategories.map(category => (
                                        <MenuItem value={category} key={category}>{category}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <NewFolderDialog/>
                        </Grid>
                        <Grid item>
                            <Tooltip title={deleteTooltip} placement="bottom-end">
                                <Button variant="outlined" color="secondary" onClick={this.handleCategoryDelete}>
                                    Usuń kategorię
                                </Button>
                            </Tooltip>

                        </Grid>
                    </Grid>

                    <GalleryPage category={this.state.category}/>

                    <div className={classes.appBarSpacer}/>
                </div>
            );
        }
    }
}

GalleryFolders.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['images/categories']), // or { collection: 'todos' }
    connect((state) => ({
        // images: state.firestore.data.images && state.firestore.data.images["_base"],
        images: state.firestore.data.images,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(GalleryFolders)