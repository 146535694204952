import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const Switches = (props) => {
    return (
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Pytania w formularzu:</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.actualState.name}
                                onChange={props.handleChange('name')}
                                value="name"
                            />
                        }
                        label="Imię i nazwisko"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.actualState.organisation}
                                onChange={props.handleChange('organisation')}
                                value="organisation"
                            />
                        }
                        label="Organizacja"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.actualState.email}
                                onChange={props.handleChange('email')}
                                value="email"
                            />
                        }
                        label="Email"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.actualState.phone}
                                onChange={props.handleChange('phone')}
                                value="phone"
                            />
                        }
                        label="Telefon"
                    />
                </FormGroup>
            </FormControl>

        </div>
    );
};

export default Switches;