import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import LoadingScreen from "../../Login/LoadingScreen";
import {compose} from "redux";
import {connect} from "react-redux";
import NewMobileAppFolderDialog from "./NewMobileAppFolderDialog";
import Divider from "@material-ui/core/Divider";
import MobileAppChoosersInCategoryList from "./MobileAppChoosersInCategoryList";
import NewMobileAppChooserDialog from "./NewMobileAppChooserDialog";
import ImageSelectDialog from "../Choosers/EditorTabs/ImageSelectDialog";
import ImageRenderer from "../../ImageRenderer";
import {withSnackbar} from "notistack";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
});

const deleteTooltip = `
            Usuniętej kategorii nie będzie widać, 
            ale zdjęcia nie znikną z wybieraczek, w których są już używane. 
            Stworzenie ponownie kategorii o identycznej nazwie pozwala na cofnięcie tej oparacji.
        `;

class MobileApp extends Component {
    state = {
        category: ""
    };

    handleChange = (event) => {
        this.setState({category: event.target.value})
    };

    handleCategoryDelete = () => {
        const ref = this.props.firestore.collection('mobileApp').doc('categories');
        const collection = 'mobileApp';
        const populates = [];

        ref.update({
            [this.state.category]: this.props.firestore.FieldValue.delete()

        })
            .then(() => {
                this.props.firestore.get({collection, populates})
            });

        this.setState({category: ""});
    };

    handleCategoryImageChange = (newImageLink) => {
        const ref = this.props.firestore.collection('mobileApp').doc('categories');

        ref.update({
            [`${this.state.category}.image`]: newImageLink
        })
            .then(() => {
                this.props.enqueueSnackbar("Dodano obrazek!", {
                    variant: 'success',
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Wystąpił błąd --- " + error, {
                    variant: 'error',
                })
            });
    };

    render() {
        const {classes, mobileApp} = this.props;

        if (!isLoaded(mobileApp))
            return (<div>
                <div className={classes.appBarSpacer}/>
                <LoadingScreen/>
            </div>);
        else {
            const mobileAppCategories = mobileApp.categories;
            console.log(mobileAppCategories);

            return (
                <div>
                    <div className={classes.appBarSpacer}/>

                    <Grid container spacing={24} justify="center" alignItems="center">
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="category">Kategoria</InputLabel>
                                <Select
                                    value={this.state.category}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'category',
                                        id: 'category',
                                    }}
                                >
                                    {
                                        Object.keys(mobileAppCategories).map((key) => {
                                            return <MenuItem value={key} key={key}>{key}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <NewMobileAppFolderDialog/>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color={"primary"}
                                    onClick={() => this.setState({categoryImageDialogOpen: true})
                                    }>
                                Obrazek kategorii
                            </Button>
                            <ImageSelectDialog isOpen={this.state.categoryImageDialogOpen}
                                               handleClose={() => this.setState({categoryImageDialogOpen: false})}
                                               imageUrl={this.state.category && mobileAppCategories[this.state.category].image}
                                               handleSave={(newImageUrl) => {
                                                   this.handleCategoryImageChange(newImageUrl);
                                                   this.setState({
                                                       categoryImageDialogOpen: false,
                                                   })
                                               }}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={deleteTooltip} placement="bottom-end">
                                <Button variant="outlined" color="secondary" onClick={this.handleCategoryDelete}>
                                    Usuń kategorię
                                </Button>
                            </Tooltip>

                        </Grid>
                    </Grid>

                    <ImageRenderer url={this.state.category ? mobileAppCategories[this.state.category].image : ""}/>

                    <Divider variant="middle"/>

                    <MobileAppChoosersInCategoryList
                        category={this.state.category}
                        choosersList={this.state.category ? mobileAppCategories[this.state.category].choosers : []}/>
                    {this.state.category && <NewMobileAppChooserDialog category={this.state.category}/>}

                </div>
            );
        }
    }
}

MobileApp.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(
    firestoreConnect(['mobileApp/categories']), // or { collection: 'todos' }
    connect((state) => ({
        mobileApp: state.firestore.data.mobileApp,
    })),
    withStyles(styles),
    withSnackbar,
)(MobileApp)
