import {compose, createStore} from 'redux'
import {reactReduxFirebase} from 'react-redux-firebase'
import firebase from 'firebase'
import 'firebase/firestore'
import {reduxFirestore} from "redux-firestore";
import {rootReducer} from "./reducers";


export function fireBaseInit() {
    const reactReduxFirebaseConfig = {
        useFirestoreForProfile: true,
        userProfile: 'users', // firebase root where user profiles are stored
        enableLogging: false, // enable/disable Firebase's database logging
        allowMultipleListeners: true,
    };

    const firebaseConfig = {
        apiKey: "AIzaSyD6f5lu4k_Wrv0hk51o4InJFgeRkrHO2gc",
        authDomain: "wybierator-produktow.firebaseapp.com",
        databaseURL: "https://wybierator-produktow.firebaseio.com",
        projectId: "wybierator-produktow",
        storageBucket: "wybierator-produktow.appspot.com",
        messagingSenderId: "868417255913"
    };

    firebase.initializeApp(firebaseConfig);

    firebase.firestore().settings({timestampsInSnapshots: true});

    const createStoreWithFirebase = compose(
        reactReduxFirebase(firebase, reactReduxFirebaseConfig),
        reduxFirestore(firebase),
    )(createStore);

    const initialState = {};
    return createStoreWithFirebase(rootReducer, initialState)
}