import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";

const AnswersDialogContent = (props) => {
    return (
        <div style={{padding: "30px"}}>
            <DialogTitle>Odpowiedzi użytkownika:</DialogTitle>
            <Divider style={{margin: "30px"}}/>
            {
                props.answers.map((question) => (
                    <>
                        <Typography variant="h5" gutterBottom>
                            {question.question}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {question.answer}
                        </Typography>
                        <Divider style={{margin: "30px"}}/>
                    </>
                ))
            }
            <Typography variant="h4" gutterBottom>
                {props.chosenProductName}
            </Typography>
        </div>
    );
};

export default AnswersDialogContent;