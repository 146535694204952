import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import ChoosersDataTable from "./ChoosersDataTable";
import Grid from "@material-ui/core/Grid/Grid";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    tableContainer: {
        // height: 320,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
        marginLeft: "50px",
        direction: "column",
        justify: "center",
        alignItems: "stretch",
    },
});

class Choosers extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div>
                <div className={classes.appBarSpacer}/>
                <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
                    <Grid item>
                        <Typography variant="display1" gutterBottom>
                            Twoje wybieraczki
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/*<CreateChooserDialog/>*/}
                        <Link to={"/wybieraczki/dodaj"}>
                            <Button variant="extendedFab" color={"primary"} className={classes.button}>
                                <AddIcon/>
                                Dodaj
                            </Button>
                        </Link>
                    </Grid>
                </Grid>


                <div className={classes.tableContainer}>
                    <ChoosersDataTable/>
                </div>
            </div>
        );
    }
}

Choosers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Choosers);
