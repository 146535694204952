import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingScreen from "./MobileApp";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import {Divider} from "@material-ui/core";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    list: {
        width: "500px",
        margin: "auto"
    }
});

class MobileAppChoosersInCategoryList extends Component {

    handleChooserDeletion = (element) => {
        const docRef = this.props.firestore.collection("mobileApp").doc("categories");

        docRef.update({
            [`${this.props.category}.choosers`]: this.props.firebase.firestore.FieldValue.arrayRemove(element)
        })
            .then(() => {
                this.props.enqueueSnackbar("Usunięto wybieraczkę z kategorii!", {
                    variant: 'success',
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Wystąpił błąd --- " + error, {
                    variant: 'error',
                })
            });
    };

    render() {
        const {classes, choosersList} = this.props;

        if (!(choosersList))
            return (<div>
                <div className={classes.appBarSpacer}/>
                <LoadingScreen/>
            </div>);
        else {
            return (
                <div>
                    <List className={classes.list}>
                        {choosersList.map((chooser) => (
                            <React.Fragment key={chooser.value}>
                                <ListItem>
                                    {/*<ListItemAvatar>*/}
                                    {/*<Avatar>*/}
                                    {/*<FolderIcon />*/}
                                    {/*</Avatar>*/}
                                    {/*</ListItemAvatar>*/}
                                    <ListItemText
                                        primary={chooser.label}
                                        // secondary={secondary ? 'Secondary text' : null}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="Delete" onClick={() => this.handleChooserDeletion(chooser)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider/>
                            </React.Fragment>
                        ))}
                    </List>
                </div>
            );
        }
    }
}

MobileAppChoosersInCategoryList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki', 'users']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.ordered.wybieraczki,
        users: state.firestore.data.users,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(MobileAppChoosersInCategoryList)