import React from 'react';

const ImageRenderer = (url, key) => {
    // const style = imageUrl ?
    //     {backgroundImage: 'url(' + imageUrl + ')'} :
    //     undefined;
    return (
        <div style={{height: "200px"}}>
            <img src={url.url} alt={""} style={{height: "200px"}} />
        </div>
    );
};

export default ImageRenderer;