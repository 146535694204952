import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import {CSVLink} from "react-csv";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const styles = {
    button: {
        margin: "20px",
    },
};

function StatisticsChart(props) {
    const {classes, data, users, dateFrom, dateTo, exports, userToStats, chooserName} = props;
    const dataArray = [];

    let dataToDisplay = data[0] ? data[0] : {};

    if (!exports.some(e => e.value === "allUsers"))
        exports.push({value: "allUsers"});

    for (let iteratorDate = new Date(dateFrom); iteratorDate <= dateTo; iteratorDate.setDate(iteratorDate.getDate() + 1)) {
        const iteratorDay = (iteratorDate.toISOString().slice(0, 10));
        const dayObj = {
            name: iteratorDay,
        };
        const day = dataToDisplay[iteratorDay];
        exports.map((userObject) => {
            if (day) {
                if (dataToDisplay[iteratorDay][userObject.value])
                    dayObj[userObject.value] = dataToDisplay[iteratorDay][userObject.value];
                else
                    dayObj[userObject.value] = 0;
            } else
                dayObj[userObject.value] = 0;
        });
        dataArray.push(dayObj);
    }


    const usersArray = [];
    exports.map((userObject) => {
        if (userToStats === "" || userToStats === userObject.value)
            usersArray.push(userObject.value);
    });


    const exportArray = JSON.parse(JSON.stringify(dataArray));

    exportArray.map(dayObj => {
        for (const field in dayObj) {
            // if (usersArray.includes(field)) {
            // console.log(dayObj);
            if (users[field]) {
                dayObj[users[field].username] = dayObj[field];
                delete dayObj[field];
            }
            // }
        }
    });

    // console.log(exportArray);


    return (
        // 99% per https://github.com/recharts/recharts/issues/172
        <React.Fragment>
            <ResponsiveContainer width="99%" height={320}>
                <LineChart data={dataArray}>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Legend/>
                    {
                        usersArray.map((user) => {
                            return <Line type="monotone" dataKey={user} key={user}
                                         name={user === "allUsers" ? "Suma" : users[user].username}
                                         stroke={getRandomColor()}/>
                        })}
                    {/*<Line type="monotone" dataKey="Orders" stroke="#8884d8" activeDot={{r: 8}}/>*/}
                </LineChart>
            </ResponsiveContainer>
            <CSVLink data={exportArray} separator={";"}
                     filename={`wybierator_${chooserName}_${dateFrom.toISOString().slice(0, 10)}-${dateTo.toISOString().slice(0, 10)}.csv`}>
                <Button color="primary" variant="contained" className={classes.button}>
                    Pobierz CSV
                </Button>
            </CSVLink>
        </React.Fragment>
    );
}

export default withStyles(styles)(StatisticsChart);
