import MUIDataTable from "mui-datatables";

import React, {Component} from 'react';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button/Button";

let counter = 0;

function createData(id, username, email, creation_date, role, classes, userDeleteFunction) {
    counter += 1;
    const actionButtons = <div>
        <Button onClick={() => userDeleteFunction(id)} variant="contained">Usuń</Button>

        {/*<Link to={"/wybieraczki/" + id}>*/}
        {/*<Button variant="contained" target="_blank" className={classes.button}>Edytuj</Button>*/}
        {/*</Link>*/}
        {/*<Button href={"http://wybieraczka.surge.sh/" + id} variant="contained" target="_blank"*/}
        {/*className={classes.button}>Link</Button>*/}
    </div>;
    return [username, email, creation_date, role, actionButtons];
}

const columns = ["Nazwa użytkownika", "Email", "Data utworzenia", "Rola", "Akcje"];

const options = {
    filter: false,
    selectableRows: false,
    pagination: false,
    rowsPerPage: 1000,
    print: false,
    download: false,
    viewColumns: false,
};

const styles = theme => ({
    button: {
        margin: theme.spacing.unit * 2
    },
});

class UsersTable extends Component {
    data = [];

    userDeleteAction = (userId) => {
        this.props.firestore.set({collection: 'users', doc: userId},
            {deleted: true},
            {merge: true});
    };

    render() {
        const {classes, theme, className} = this.props;
        if (typeof this.props.users !== 'undefined') {
            this.data = [];
            console.log(this.props.users);
            for (let userid in this.props.users) {
                // console.log(userid);
                const user = this.props.users[userid];
                if (!user.deleted)
                    this.data.push(createData(userid, user.username, user.email || "---", user.creationDate || "---", user.role || "---", classes, this.userDeleteAction));
            }


        }
        return (
            <div>
                <MUIDataTable
                    title={"Lista użytkowników"}
                    data={this.data}
                    columns={columns}
                    options={options}
                />
            </div>
        );
    }
}

UsersTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['users']), // or { collection: 'todos' }
    connect((state) => ({
        users: state.firestore.data.users
    })),
    withStyles(styles)
)(UsersTable)