import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {compose} from "redux";
import {withFirestore} from "react-redux-firebase";
import LoadingScreen from "../../Login/LoadingScreen";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";

const redirect = (id) => {
    return <Redirect to={"/wybieraczki/" + id}/>;
};

class ChoosersAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {id: false};
        let {firestore: {add}} = props;
        const actualDateInMs = new Date();
        add({collection: 'wybieraczki'}, {
            name: "",
            description: "",
            startImageUrl: "",
            deleted: false,
            productsColumns: [],
            productsRows: [],
            questions: [],
            resultType: "link",
            colorPrimary: "#03a9f4",
            colorSecondary: "#cddc39",
            multiResultOption: "losuj",
            viewsCounter: 0,
            dailyStatistics: {},
            creationDate: actualDateInMs.toISOString().slice(0, 10),
            creator: props.auth.uid
        })
            .then((docRef) => {
                    redirect(docRef.id);
                    this.setState({id: docRef.id});
                }
            )
        ;
    }

    render() {
        if (this.state.id)
            return <Redirect to={"/wybieraczki/" + this.state.id}/>;
        else
            return (<LoadingScreen/>);
    }
}

ChoosersAdd.propTypes = {firestore: PropTypes.any};

export default compose(
    withFirestore,
    connect((state) => ({
        auth: state.firebase.auth // pass auth data as this.props.auth
    }))
)(ChoosersAdd);