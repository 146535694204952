import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper/Paper";
import Switches from "./Switches";
import Button from "@material-ui/core/Button";
import DataTable from "./DataTable";

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    button: {
        margin: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    textField: {
        width: "100%"
    },
});

class DataCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: true,
            organisation: true,
            email: true,
            phone: true,
        };
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.checked});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.updateHeight();
        if (this.props.chooser)
            if (this.props.chooser.dataCollection)
                this.setState(this.props.chooser.dataCollection);
    }

    render() {
        const {classes, wybieraczki, chooserData} = this.props;
        return (
            <div>
                <Paper className={classes.paper}>
                    <Switches actualState={this.state} handleChange={this.handleChange}/>
                    <Button variant="extendedFab" color={"secondary"} onClick={() => {
                        this.props.onSubmit(this.state)
                    }} className={classes.button}>
                        Zapisz
                    </Button>
                </Paper>
                <DataTable/>
            </div>
        );
    }
}

DataCollection.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    firestoreConnect(['wybieraczki', 'users']), // or { collection: 'todos' }
    connect((state) => ({
        wybieraczki: state.firestore.data.wybieraczki,
        users: state.firestore.data.users,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withStyles(styles, {withTheme: true}),
    withSnackbar,
    withRouter,
)(DataCollection)