import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from "react-redux";
import {fireBaseInit} from "./fireBaseInit";
import {SnackbarProvider} from 'notistack';


const store = fireBaseInit();

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
                <App/>
            </SnackbarProvider>
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));
registerServiceWorker();
