import React from 'react';
import PropTypes from "prop-types";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Paper from "@material-ui/core/Paper/Paper";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import connect from "react-redux/es/connect/connect";
import {withFirebase} from "react-redux-firebase";
import {UserIsNotDeleted} from "./AuthHOC";
import {withRouter} from "react-router-dom";


const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

function UserDeletedInfo(props) {
    const {classes, auth, firebase} = props;
    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1" variant="headline">
                        Wybierator
                    </Typography>
                    <Typography variant="body1">
                        Użytkownik powiązany z adresem: {auth.email}<br/>
                        został usunięty.<br/>
                        Skontaktuj się z administratorem.
                    </Typography>
                    <Button
                        onClick={() => {
                            firebase.logout()
                        }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Wyloguj
                    </Button>
                </Paper>
            </main>
        </React.Fragment>
    );
}

UserDeletedInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withFirebase,
    withStyles(styles),
    connect((state) => ({
        auth: state.firebase.auth,
        profile: state.firebase.profile
    })),
    withRouter,
    UserIsNotDeleted
)(UserDeletedInfo);